import { PayloadAction } from '@reduxjs/toolkit';
import { RX_STATUS } from 'enums/prescription';
import _ from 'lodash';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx,
    medicineCabinetToggleAutoRefillAllRxs,
    medicineCabinetToggleAutoRefillForRx
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import MedicineCabinetService, {
    RxStatusResponse,
    ToggleAutoFillRequest,
    ToggleAutoFillResponse
} from 'state/medicine-cabinet/medicine-cabinet.services';

import { RxDetails } from 'types/prescription';

import { UnknownFunction } from 'util/function';
import { getRxDisplayStatus, getRxStatus } from 'util/payload-to-props';
import { baseEffectHandler } from 'util/sagas/sagas';

import { showNewPrescriptionModal } from './medicine-cabinet.reducers';
import { medicineCabinetPrescriptionsSelector } from './medicine-cabinet.selectors';

export default function* medicineCabinetSaga() {
    yield takeLatest(
        medicineCabinetGetAllPrescriptions.TRIGGER,
        function* (
            action: PayloadAction<{
                showNewRxModal: boolean;
                epostNumFamilyMember?: string;
                onSuccess?: UnknownFunction;
                onFailure?: UnknownFunction;
            }>
        ) {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const { showNewRxModal, epostNumFamilyMember } = action.payload;

            yield baseEffectHandler({
                service: MedicineCabinetService.allRxs().get,
                data: epostNumFamilyMember,
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data: RxDetails[]) {
                    try {
                        const prescriptions: RxDetails[] = (data ?? []).map((prescription: RxDetails) => ({
                            ...prescription,
                            // realRxCardStatus contains the "real" status, ignoring if the
                            // prescription is in the cart.
                            realRxCardStatus: getRxStatus(prescription),
                            // rxCardStatus contains the status to display in
                            // the rx card, includes in cart status.
                            rxCardStatus: getRxDisplayStatus(prescription)
                        }));

                        //Check if data is returned from the api
                        if (prescriptions.length > 0) {
                            //Diff check for rxs that are stored for modal vs sent from endpoint

                            //Extracts the rx numbers from the api
                            const newRxs: string[] = prescriptions
                                .filter((prescription: RxDetails) => prescription.realRxCardStatus === RX_STATUS.NEW_RX)
                                .map((prescription: RxDetails) => prescription.rxNumber);

                            if (newRxs.length > 0) {
                                //Get our cached storage for pass modals displayed. Contains rx numbers
                                const displayedRxsString = localStorage.getItem('newRxsDisplayed');
                                //If we have have data in local storage, then we need to apply some logic
                                if (displayedRxsString !== null) {
                                    //Gets the chached modal data from localStorage
                                    const displayRxData: { prescriptions: string[]; time: string } =
                                        JSON.parse(displayedRxsString);

                                    //Use lodash differance to determine if the api sent over new Prescriptions from the ones we have cached
                                    const diffRxs = _.difference(newRxs, displayRxData.prescriptions);
                                    //Used to test if the date cache is two days old, so we can display a new modal reminding the user.
                                    const storedDate = new Date(displayRxData.time);
                                    storedDate.setDate(storedDate.getDate() + 2);
                                    //If we are visting back to the page two days later and we have do not have new rx numbers from the api
                                    //we should display a modal to the user.
                                    if (storedDate.valueOf() < Date.now() && diffRxs.length <= 0) {
                                        const prescriptionNames = newRxs
                                            .map((rx) => data.find((pre) => pre.rxNumber === rx))
                                            .map((pre) => (pre ? pre.dispensedProductName : ''));
                                        if (
                                            prescriptionNames.length > 0 &&
                                            _.join(prescriptionNames, '').trim().length > 0
                                        ) {
                                            yield put(
                                                showNewPrescriptionModal({
                                                    isRxLoaded: true,
                                                    show: showNewRxModal,
                                                    prescriptions: prescriptionNames
                                                })
                                            );
                                        }
                                        localStorage.setItem(
                                            'newRxsDisplayed',
                                            JSON.stringify({
                                                time: Date.now(),
                                                prescriptions: newRxs
                                            })
                                        );
                                        //If we do have some new rxs from the api, we should show that to the user, and update our cache state.
                                    } else if (diffRxs.length > 0) {
                                        //Update out local state with new diff rxs and show the new ones to the user.
                                        localStorage.setItem(
                                            'newRxsDisplayed',
                                            JSON.stringify({
                                                ...displayRxData,
                                                prescriptions: [...displayRxData.prescriptions, ...diffRxs]
                                            })
                                        );

                                        const prescriptionNames = diffRxs
                                            .map((rx) => data.find((pre) => pre.rxNumber === rx))
                                            .map((pre) => (pre ? pre.dispensedProductName : ''));
                                        if (
                                            prescriptionNames.length > 0 &&
                                            _.join(prescriptionNames, '').trim().length > 0
                                        ) {
                                            yield put(
                                                showNewPrescriptionModal({
                                                    isRxLoaded: true,
                                                    show: showNewRxModal,
                                                    prescriptions: prescriptionNames
                                                })
                                            );
                                        }
                                    }
                                } else {
                                    // If we have a fresh state of rxs, then we just display them to the user and store cache info
                                    localStorage.setItem(
                                        'newRxsDisplayed',
                                        JSON.stringify({
                                            time: Date.now(),
                                            prescriptions: newRxs
                                        })
                                    );
                                    const prescriptionNames = newRxs
                                        .map((rx) => data.find((pre) => pre.rxNumber === rx))
                                        .map((pre) => (pre ? pre.dispensedProductName : ''));
                                    if (
                                        prescriptionNames.length > 0 &&
                                        _.join(prescriptionNames, '').trim().length > 0
                                    ) {
                                        yield put(
                                            showNewPrescriptionModal({
                                                isRxLoaded: true,
                                                show: showNewRxModal,
                                                prescriptions: prescriptionNames
                                            })
                                        );
                                    }
                                }
                            }

                            yield put(medicineCabinetGetAllPrescriptions.success(prescriptions));
                            const { onSuccess } = action.payload;
                            if (onSuccess) onSuccess();
                        } else if (data.length === 0) {
                            yield put(medicineCabinetGetAllPrescriptions.success([]));
                        }
                    } catch (error) {
                        yield put(medicineCabinetGetAllPrescriptions.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    }
                },
                *onError(data) {
                    yield put(medicineCabinetGetAllPrescriptions.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    function* toggleAutoRefillForRxSaga(
        action: PayloadAction<{
            rxNumber: string;
            rxSeqNum: string;
            autoRefillEnabled: boolean;
            onSuccess?: () => void;
            onFailure?: () => void;
            isRenew?: boolean;
        }>
    ) {
        try {
            const { rxNumber, autoRefillEnabled, isRenew, onSuccess, onFailure } = action.payload;
            const currentPrescriptions: RxDetails[] = yield select(medicineCabinetPrescriptionsSelector);

            const data: ToggleAutoFillRequest = {
                RxNumber: rxNumber,
                AutoFillToggle: autoRefillEnabled
            };

            yield baseEffectHandler({
                service: MedicineCabinetService.toggleAutofillForRx().post,
                data,
                *onResponse(data: ToggleAutoFillResponse) {
                    if (!data.messageErrorText) {
                        // success
                        const currentRx = currentPrescriptions.find((rx) => rx.rxNumber === rxNumber);
                        const updatedRx = {
                            ...currentRx,
                            autoRefillEnabled: autoRefillEnabled,
                            // DRX-2084: if isRenew is true reset consentExpiration in order to remove renew button from UI
                            consentExpiration: isRenew ? '' : currentRx?.consentExpiration
                        };

                        const newRxs = currentPrescriptions.map((rx) => {
                            return rx.rxNumber === updatedRx.rxNumber ? updatedRx : rx;
                        });

                        yield put(medicineCabinetToggleAutoRefillForRx.success(newRxs));
                        if (onSuccess) onSuccess();
                    } else {
                        // error
                        yield put(medicineCabinetToggleAutoRefillForRx.failure(data));
                        if (onFailure) onFailure();
                    }
                    return data;
                },
                *onError(error) {
                    yield put(medicineCabinetToggleAutoRefillForRx.failure(error));
                }
            });
        } catch (error) {}
    }

    yield takeLatest(medicineCabinetToggleAutoRefillForRx.TRIGGER, toggleAutoRefillForRxSaga);

    yield takeLatest(
        medicineCabinetToggleAutoRefillAllRxs.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumbers: ToggleAutoFillRequest[];
                onSuccess?: UnknownFunction;
                onFailure?: UnknownFunction;
                isRenew?: boolean;
            }>
        ) {
            try {
                const { rxNumbers, onSuccess, onFailure } = action.payload;

                yield all(
                    rxNumbers.map((rxAutoFillRequest) => {
                        const payloadForSaga: PayloadAction<{
                            rxNumber: string;
                            rxSeqNum: string;
                            autoRefillEnabled: boolean;
                            onSuccess?: () => void;
                            onFailure?: () => void;
                            isRenew?: boolean;
                        }> = {
                            payload: {
                                rxNumber: rxAutoFillRequest.RxNumber,
                                rxSeqNum: rxAutoFillRequest.RxSeqNum,
                                autoRefillEnabled: rxAutoFillRequest.AutoFillToggle,
                                onSuccess,
                                onFailure,
                                isRenew: rxAutoFillRequest.isRenew
                            },
                            type: ''
                        };

                        const response = call(toggleAutoRefillForRxSaga, payloadForSaga);
                        return response;
                    })
                );
                if (onSuccess) onSuccess();
            } catch (error) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        }
    );

    yield takeEvery(
        medicineCabinetGetStatusForRx.TRIGGER,
        function* getSubStatusForRxSaga(
            action: PayloadAction<{
                rxNumber: string;
                epostNumFamilyMember?: string;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const { rxNumber, epostNumFamilyMember } = action.payload;

            const response: RxStatusResponse = yield call(
                MedicineCabinetService.getStatusForRx().get,
                rxNumber,
                epostNumFamilyMember
            );
            if (response.messageErrorText) {
                yield put(medicineCabinetGetStatusForRx.failure({ ...response, rxNumber }));
            } else {
                yield put(medicineCabinetGetStatusForRx.success({ ...response, rxNumber }));
            }
        }
    );
}
