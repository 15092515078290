import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
// Components
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartCompleteOrderRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { cartOrderPaymentCardSelector } from 'state/cart/cart.selectors';
import { ExtendedCartObjectPayload, OrderV2Payload } from 'state/cart/cart.services';

// Schema
import { formatPrice } from 'schema/price.schema';

import { RxDetails } from 'types/prescription';

import { isAxiosError } from 'util/axiosClient';
// Utils
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// Hooks
import { useHealthConditions } from 'hooks/useHealthConditions';

import './cart-total.style.scss';

interface ReviewCartTotalProps {
    isUnknownPrice: boolean;
    initialOrderPrice?: string;
    currentShippingPrice: number;
    isInsuranceAccount: boolean;
    isBirdiCash: boolean;
    cartObject?: OrderV2Payload[];
    prescriptionsObject: RxDetails[];
}

export const CartTotal = ({
    isUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    isInsuranceAccount,
    cartObject,
    prescriptionsObject,
    isBirdiCash
}: ReviewCartTotalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isOneClickOrderAvailable, setIsOneClickOrderAvailable] = useState<boolean>(false);

    const profileObject = useSelector(accountProfileSelector);

    const { userHasNotSubmittedAllergies, userHasNotSubmittedConditions } = useHealthConditions(
        profileObject?.hasMembership
    );

    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);

    useEffect(() => {
        setIsOneClickOrderAvailable(
            !userHasNotSubmittedConditions && !userHasNotSubmittedAllergies && !!selectedPaymentMethod
        );
    }, [userHasNotSubmittedConditions, userHasNotSubmittedAllergies, selectedPaymentMethod]);

    const showPaymentError = () => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleSubmitOrderClick = () => {
        setIsBusy(true);
        dispatch(
            cartCompleteOrderRoutine({
                currentShippingPrice: Number(currentShippingPrice),
                lineItems: cartObject?.flatMap((rx) => rx?.extendedRefillRxs).filter((item) => !!item) || [],
                onSuccess: () => {
                    setIsBusy(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: cartObject as ExtendedCartObjectPayload[],
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: String(currentShippingPrice),
                        accountHasInsurance: isInsuranceAccount
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: (data: unknown) => {
                    if (
                        data &&
                        typeof data === 'object' &&
                        'response' in data &&
                        isAxiosError(data.response) &&
                        data.response.response?.data.SystemMessage === 'PRE-AUTH-DENIED'
                    ) {
                        showPaymentError();
                    } else {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.cart.error.errorTitle`)}
                                        body={t(`pages.cart.error.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.cart.error.cta`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                    dispatch(getCartRoutine.trigger());
                    setIsBusy(false);
                }
            })
        );
    };

    return (
        <Container fluid className="cart-total__wrapper">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total d-flex justify-content-between">
                        <div>
                            {t('pages.cart.subTotal')}
                            {isUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div>{formatPrice(initialOrderPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-between mt-3">
                        <div>{t('pages.cart.shipping')}</div>
                        <div>{formatPrice(currentShippingPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-between">
                        <div className="h6 cart-total--title">
                            {t('components.medicineCabinetCart.newCartTotal.total')}
                            {isUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div className="h6 cart-total--price">
                            {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column cart-total__container-notes">
                    {isInsuranceAccount ? (
                        <ToastBox icon="default" variant="info">
                            <div className="cart-total__note">
                                {t('components.medicineCabinetCart.newCartTotal.insuranceDisclaimer')}
                            </div>
                        </ToastBox>
                    ) : (
                        !isInsuranceAccount &&
                        isBirdiCash && (
                            <ToastBox icon="default" variant="info">
                                <div className="cart-total__note">
                                    {t('components.medicineCabinetCart.newCartTotal.birdiPriceDisclaimer')}
                                </div>
                            </ToastBox>
                        )
                    )}
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column cart-total__container-cta">
                    <Button
                        async
                        isBusy={isBusy}
                        disabled={!isOneClickOrderAvailable || isBusy}
                        type="button"
                        className="btn btn-primary no-min-width btn-bold"
                        label={t('components.medicineCabinetCart.newCartTotal.submit')}
                        onClick={handleSubmitOrderClick}
                    />
                    <Button
                        type="button"
                        variant="text"
                        className="btn btn-text text-cerulean no-min-width btn-demi"
                        label={t('components.medicineCabinetCart.newCartTotal.review')}
                        onClick={() => navigate('/secure/cart/review')}
                    />

                    <div className="cart-total__disclaimer">
                        {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                    </div>
                </Col>
            </Row>
            <br />
        </Container>
    );
};
