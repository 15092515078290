import { BIRDI_PLANS } from 'enums/plans';
import {
    RX_AVAILABLE_FLOWS,
    RX_CURRENT_STEP_DESCRIPTION,
    RX_ORDER_LINE_QUEUE_STATUS,
    RX_ORDER_STATUS,
    RX_STATUS,
    RX_STATUS_PAYLOAD,
    RX_WEB_ELIGIBILITY_STATUS
} from 'enums/prescription';
import { TFunction } from 'gatsby-plugin-react-i18next';
import moment from 'moment';

import { PrescriptionCardProps, PrescriptionDetail, RxActions, RxDetails, StatusProps } from 'types/prescription';

import { getPhoneNumber } from 'util/globalVariables';
import { isNullOrEmptyObject } from 'util/object';
import { isRxExpired, isRxOnHold, isShipStatusExpired } from 'util/prescription';

// ------------------------------
// ----- Payload to Props -------
// ------------------------------

// Validate mapped Rx status (from payloadToProps) to know
// if the card should show the Rx price.
export function shouldRxShowPrice(status: string) {
    return ![
        RX_STATUS.TRANSFER_PENDING,
        RX_STATUS.NEW_REQUEST_PENDING,
        RX_STATUS.ON_HOLD,
        RX_STATUS.ORDER_STATUS_PENDING,
        RX_STATUS.NOT_INCLUDED,
        RX_STATUS.NO_RESPONSE_PRESCRIBER,
        RX_STATUS.ASSISTANCE_NEEDED
    ].find((invalidStatus) => invalidStatus === status);
}

// Gets an updated status order for ordered sub statuses
export const getSortOrderForRxSubStatusStep = (currentStep?: number): number => {
    const sortOrders: Record<string, number> = {
        [RX_CURRENT_STEP_DESCRIPTION.PROCESSING]: 91,
        [RX_CURRENT_STEP_DESCRIPTION.FILLING_IN_PHARMACY]: 92,
        [RX_CURRENT_STEP_DESCRIPTION.PACKAGED]: 93,
        [RX_CURRENT_STEP_DESCRIPTION.SHIPPED]: 94,
        [RX_CURRENT_STEP_DESCRIPTION.OUT_FOR_DELIVERY]: 95,
        [RX_CURRENT_STEP_DESCRIPTION.DELIVERED]: 96
    };

    return sortOrders[currentStep ?? RX_CURRENT_STEP_DESCRIPTION.UNKNOWN] ?? 90;
};

// Function that returns the sorting order for a certain rx status
export const getRxSortOrder = (prescription: RxDetails): number => {
    const sortOrders: Record<string, number> = {
        [RX_STATUS.IN_CART]: 10,
        [RX_STATUS.NEW_RX]: 20,
        [RX_STATUS.REFILL_AVAILABLE]:
            moment(prescription.nextFillDate).diff(moment().format('MM/DD/YYYY'), 'days') < 1 ? 30 : 31,
        [RX_STATUS.NO_RESPONSE_PRESCRIBER]: 40,
        [RX_STATUS.OUT_OF_REFILLS]: 50,
        [RX_STATUS.REFILL_TOO_SOON]: 60,
        [RX_STATUS.NEW_REQUEST_PENDING]: 70,
        [RX_STATUS.TRANSFER_PENDING]: prescription.rxNumber && prescription.rxNumber !== 'N/A' ? 85 : 80,
        [RX_STATUS.ORDERED]: getSortOrderForRxSubStatusStep(prescription.rxSubStatus?.CurrentStep),
        [RX_STATUS.SHIPPED]: getSortOrderForRxSubStatusStep(prescription.rxSubStatus?.CurrentStep),
        [RX_STATUS.ON_HOLD]: 100,
        [RX_STATUS.ORDER_STATUS_PENDING]: 110,
        [RX_STATUS.NOT_INCLUDED]: 120,
        [RX_STATUS.EXPIRED]: 130,
        [RX_STATUS.ASSISTANCE_NEEDED]: 150
    };

    // Sort order will depend on the status to display
    // so we are NOT using realRxCardStatus
    return sortOrders[prescription.rxCardStatus];
};

// Function that returns the rx status props to display in the cart
export const getRxStatusProps = (
    prescription: RxDetails,
    t: TFunction<'translation'>,
    isRxInCart = false
): StatusProps[] => {
    const statusProps: Record<string, StatusProps[]> = {
        [RX_STATUS.IN_CART]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.inYourCart')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.NEW_RX]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderNewRxArrived')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.ORDERED]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
                displayType: 'RX'
            },
            {
                primary: false,
                status: <div>{t('components.prescriptionCard.orderInProgress')}</div>,
                displayType: 'ORDER'
            }
        ],
        [RX_STATUS.SHIPPED]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
                displayType: 'RX'
            },
            {
                primary: false,
                status: (
                    <div>
                        {!prescription.lastFillDate
                            ? t('components.prescriptionCard.orderProcessingText')
                            : t('components.prescriptionCard.orderShippedText')}
                    </div>
                ),
                displayType: 'ORDER'
            }
        ],
        [RX_STATUS.REFILL_TOO_SOON]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderRefillTooSoon')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.REFILL_AVAILABLE]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderRefillAva')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.ASSISTANCE_NEEDED]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.assistanceNeeded')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.ON_HOLD]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.rxOnHold')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.ORDER_STATUS_PENDING]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderStatusPending')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.NOT_INCLUDED]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.notIncludedInYourPlan')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.TRANSFER_PENDING]: [
            {
                primary: true,
                status: (
                    <div>
                        {prescription.rxNumber && prescription.rxNumber !== 'N/A'
                            ? t('components.prescriptionCard.transferPending')
                            : t('components.prescriptionCard.requestPending')}
                    </div>
                ),
                displayType: 'RX'
            }
        ],
        [RX_STATUS.NO_RESPONSE_PRESCRIBER]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderMdoCancelled')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.NEW_REQUEST_PENDING]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderMdoWaiting')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.EXPIRED]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.orderExpired')}</div>,
                displayType: 'RX'
            }
        ],
        [RX_STATUS.OUT_OF_REFILLS]: [
            {
                primary: true,
                status: <div>{t('components.prescriptionCard.outOfRefills')}</div>,
                displayType: 'RX'
            }
        ]
    };

    const status = isRxInCart ? RX_STATUS.IN_CART : prescription.rxCardStatus;
    return statusProps[status] ?? [];
};

// Function that gets the status footer for each rx
export const getRxStatusFooter = (prescription: RxDetails, t: TFunction<'translation'>): string | undefined => {
    const statusFooter: Record<string, string> = {
        [RX_STATUS.SHIPPED]: !prescription.lastFillDate
            ? t('components.prescriptionCard.orderProcessingText')
            : t('components.prescriptionCard.orderShippedText'),
        [RX_STATUS.ASSISTANCE_NEEDED]: t('components.prescriptionCard.assistanceNeededMessage', {
            phoneNumber: getPhoneNumber({})
        }),
        [RX_STATUS.ON_HOLD]: t('components.prescriptionCard.rxOnHoldFootnote', {
            phoneNumber: getPhoneNumber({ isEnd: true })
        }),
        [RX_STATUS.ORDER_STATUS_PENDING]: t('components.prescriptionCard.unknownStatusMessage'),
        [RX_STATUS.NOT_INCLUDED]: t('components.prescriptionCard.notOnFormularyMessage', {
            phoneNumber: getPhoneNumber({})
        }),
        [RX_STATUS.TRANSFER_PENDING]: t('components.prescriptionCard.pendingMessage'),
        [RX_STATUS.NO_RESPONSE_PRESCRIBER]: t('components.prescriptionCard.orderMdoCancelledMessage'),
        [RX_STATUS.NEW_REQUEST_PENDING]: t('components.prescriptionCard.orderMdoWaitingMessage'),
        [RX_STATUS.EXPIRED]: t('components.prescriptionCard.rxExpiredFootnote'),
        [RX_STATUS.OUT_OF_REFILLS]: t('components.prescriptionCard.rxOutOfRefillsFootnote')
    };

    // Footer labels will depend on the status to display
    // so we are NOT using realRxCardStatus
    return statusFooter[prescription.rxCardStatus] ?? null;
};

// Function that handles the status assignment for each Rx Card
// This function will return the real status, ignoring if the Rx is
// in the cart
export const getRxStatus = (prescription: RxDetails): RX_STATUS => {
    let rxStatus: RX_STATUS = RX_STATUS.ASSISTANCE_NEEDED;

    // TODO: Evaluate this logic, we can't use a switch statement
    // because a single rx may match different statuses and still
    // be valid, so the ifs statements helps us to "replace" the
    // status based on the order of its declarations.
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE &&
        prescription.rxStatus === RX_STATUS_PAYLOAD.PROFILED
    ) {
        rxStatus = RX_STATUS.NEW_RX;
    }
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE &&
        prescription.rxStatus !== RX_STATUS_PAYLOAD.PROFILED
    ) {
        rxStatus = RX_STATUS.REFILL_AVAILABLE;
    }
    if (prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.MDO_CANCELLED) {
        rxStatus = RX_STATUS.NO_RESPONSE_PRESCRIBER;
    }
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ &&
        !isRxExpired(prescription.rxExpirationDate) &&
        Number(prescription.fillsRemaining) === 0
    ) {
        rxStatus = RX_STATUS.OUT_OF_REFILLS;
    }
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        prescription.itemInWorkflow === false &&
        !isNullOrEmptyObject(prescription.nextFillDate) &&
        // Hotfix DRX-1981.
        // When a RX is shipped, a new RX is added to the profile with the same drug.
        // The old RX is discontinued and the new RX will be the primary RX.
        //
        // During this process, the nexFillDate will be pulled to the new RX
        // but the lastFillDate not.
        //
        // In this scenario we have to check if nexFillDate is after today
        // to consider it as a Refill to Soon.
        ((!isNullOrEmptyObject(prescription.lastFillDate) && isShipStatusExpired(prescription.lastFillDate)) ||
            (isNullOrEmptyObject(prescription.lastFillDate) && !isRxExpired(prescription.nextFillDate)))
    ) {
        rxStatus = RX_STATUS.REFILL_TOO_SOON;
    }
    if (prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.PENDING) {
        rxStatus = RX_STATUS.TRANSFER_PENDING;
    }
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        prescription.itemInWorkflow === true
    ) {
        rxStatus = RX_STATUS.ORDERED;
    }
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        prescription.orderLineQueueStatus === RX_ORDER_LINE_QUEUE_STATUS.SHIPPED &&
        prescription.itemInWorkflow === false &&
        !isShipStatusExpired(prescription.lastFillDate)
    ) {
        rxStatus = RX_STATUS.SHIPPED;
    }
    if (isRxOnHold(prescription)) {
        rxStatus = RX_STATUS.ON_HOLD;
    }
    if (
        prescription.rxSubStatus?.RxInProgressStatus === 0 ||
        (prescription.itemInWorkflow === false &&
            !prescription.inOrderCart &&
            prescription.orderStatus === RX_ORDER_STATUS.INCOMPLETE_PENDING) ||
        prescription.rxSubStatus?.CurrentStep === RX_CURRENT_STEP_DESCRIPTION.UNKNOWN
    ) {
        rxStatus = RX_STATUS.ORDER_STATUS_PENDING;
    }
    if (!prescription.inFormulary && !prescription.inCashFormulary) {
        rxStatus = RX_STATUS.NOT_INCLUDED;
    }
    if (
        prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ &&
        (prescription.inFormulary || prescription.inCashFormulary) &&
        isRxExpired(prescription.rxExpirationDate)
    ) {
        rxStatus = RX_STATUS.EXPIRED;
    }
    if (prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.MDO_WAITING) {
        rxStatus = RX_STATUS.NEW_REQUEST_PENDING;
    }
    return rxStatus;
};

// Function that returns the rx status to display in the UI
export const getRxDisplayStatus = (prescription: RxDetails): RX_STATUS => {
    return prescription.inOrderCart ? RX_STATUS.IN_CART : getRxStatus(prescription);
};

// Function that validates if the Rx should be displayed in the cart
export const shouldShowRxNum = (rxStatus: RX_STATUS): boolean => {
    // The Rx isn't any of these statuses.
    return ![RX_STATUS.NEW_REQUEST_PENDING, RX_STATUS.TRANSFER_PENDING].includes(rxStatus);
};

// Function that returns if an Rx is in progress
export const getIsInProgressRxStatus = (rxStatus: RX_STATUS): boolean => {
    return [RX_STATUS.ORDERED, RX_STATUS.SHIPPED].includes(rxStatus);
};

// function that validates if we should display order history CTA
export const doesRxHasOrderHistory = (rxStatus: RX_STATUS): boolean => {
    return (
        getIsInProgressRxStatus(rxStatus) ||
        [
            RX_STATUS.REFILL_AVAILABLE,
            RX_STATUS.EXPIRED,
            RX_STATUS.OUT_OF_REFILLS,
            RX_STATUS.REFILL_TOO_SOON,
            RX_STATUS.ON_HOLD,
            RX_STATUS.ORDER_STATUS_PENDING,
            RX_STATUS.NOT_INCLUDED,
            RX_STATUS.NO_RESPONSE_PRESCRIBER
        ].includes(rxStatus)
    );
};

// Function that validates if an Rx can be removed
export function canRxBeRemoved(rxStatus: RX_STATUS): boolean {
    return [
        RX_STATUS.NEW_RX,
        RX_STATUS.REFILL_AVAILABLE,
        RX_STATUS.EXPIRED,
        RX_STATUS.OUT_OF_REFILLS,
        RX_STATUS.REFILL_TOO_SOON,
        RX_STATUS.ON_HOLD,
        RX_STATUS.ORDER_STATUS_PENDING,
        RX_STATUS.NOT_INCLUDED,
        RX_STATUS.NO_RESPONSE_PRESCRIBER
    ].includes(rxStatus);
}

// Function that returns actions available for each RxCard
export const getRxActions = (rxStatus: RX_STATUS, realRxCardStatus: RX_STATUS, flow: RX_AVAILABLE_FLOWS): RxActions => {
    const isMedicineCabinet = flow === RX_AVAILABLE_FLOWS.MEDICINE_CABINET;
    return {
        // Validation that gets the real card status without being on the cart.
        // Order history will be shown depending on this original status.
        orderHistory: isMedicineCabinet ? doesRxHasOrderHistory(realRxCardStatus) : false,
        // For remove and track order CTA validation we need to check the display status
        // as add to cart won't have the ability of being removed, regardless its original status.
        trackOrder: isMedicineCabinet ? getIsInProgressRxStatus(rxStatus) : false,
        removeRx: isMedicineCabinet ? canRxBeRemoved(rxStatus) : false
    };
};

// Validates and gets next fill date
export const getNextFillDate = (prescription: RxDetails): StatusProps | null => {
    if (shouldShowRefillsAvailable(prescription.realRxCardStatus) && !isNullOrEmptyObject(prescription.nextFillDate)) {
        const nextFillDateFormatted = moment(prescription.nextFillDate).format('MM/DD/YYYY');
        const nextFillDateDiff = moment(prescription.nextFillDate).diff(moment().format('MM/DD/YYYY'), 'days');

        return {
            primary: false,
            isRefillDue:
                nextFillDateDiff < 1 &&
                prescription.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
                prescription.itemInWorkflow !== true,
            status: <div>{nextFillDateFormatted}</div>,
            displayType: 'NEXT_REFILL'
        };
    }
    return null;
};

// Validates and gets last fill date
export const getLastFilledDate = (prescription: RxDetails): StatusProps | null => {
    if (shouldShowRefillsAvailable(prescription.realRxCardStatus) && !isNullOrEmptyObject(prescription.lastFillDate)) {
        return {
            primary: false,
            status: <div>{prescription.lastFillDate}</div>,
            displayType: 'LAST_REFILL'
        };
    }
    return null;
};

// Function that checks if we should show refills available
const shouldShowRefillsAvailable = (rxStatus: RX_STATUS) => {
    const refillStatus = [
        RX_STATUS.REFILL_AVAILABLE,
        RX_STATUS.ORDERED,
        RX_STATUS.SHIPPED,
        RX_STATUS.EXPIRED,
        RX_STATUS.OUT_OF_REFILLS,
        RX_STATUS.REFILL_TOO_SOON
    ];

    return refillStatus.includes(rxStatus);
};

// Validate auto refill eligibility by status
const isStatusEligibleForAutoRefill = (rxStatus: RX_STATUS) => {
    const refillStatus: string[] = [
        RX_STATUS.TRANSFER_PENDING,
        RX_STATUS.NEW_REQUEST_PENDING,
        RX_STATUS.NOT_INCLUDED,
        RX_STATUS.NO_RESPONSE_PRESCRIBER
    ];

    return !refillStatus.includes(rxStatus);
};

export const shouldRxDisplayBirdiSelectFlag = (
    isBirdiSelect: boolean,
    planAlias: string | BIRDI_PLANS,
    isOnDemandPlan: boolean
) => {
    const isMembershipPaidPlan = planAlias === BIRDI_PLANS.BRD_02 && !isOnDemandPlan;

    return isMembershipPaidPlan && isBirdiSelect;
};

// Function that returns the props object for Rx cards
export const prescriptionPayloadToProps = (
    payload: RxDetails,
    t: TFunction<'translation'>,
    planAlias: string | BIRDI_PLANS = BIRDI_PLANS.BRD_01,
    isOnDemandPlan = false,
    isAddedToCart = false
): PrescriptionCardProps => {
    // This variable will store the status to display in the rxCard
    const rxStatus = isAddedToCart ? RX_STATUS.IN_CART : payload.rxCardStatus;
    const nextFillDate = getNextFillDate(payload);
    const lastFilledDate = getLastFilledDate(payload);
    const statuses = [];

    const details: PrescriptionDetail[] = [];
    if (shouldShowRxNum(payload.realRxCardStatus)) {
        details.push({
            key: 'rxNum',
            detail: t('components.prescriptionCard.rxNumberHeaderText', { number: payload.rxNumber })
        });
    }

    if (payload.fillQuantity) {
        details.push({
            key: 'qty',
            detail: t('components.prescriptionCard.quantity', {
                fillQuantity: payload.fillQuantity
            })
        });
    }

    if (nextFillDate) {
        statuses.push(nextFillDate);
    }

    if (lastFilledDate) {
        statuses.push(lastFilledDate);
    }

    const props = {
        fullRxItem: payload, // for debugging
        prescriptionName: payload.dispensedProductName,
        orderStatus: rxStatus,
        realRxCardStatus: payload.realRxCardStatus,
        sortOrder: getRxSortOrder(payload),
        inOrderCart: payload.inOrderCart,
        refillsLeft: Number(payload.fillsRemaining),
        rxNumber: payload.rxNumber,
        rxSeqNum: payload.rxSeqNum,
        autoRefillEnabled: payload.autoRefillEnabled,
        autoRefillEligible: payload.autoRefillEligible,
        webEligibilityStatus: payload.webEligibilityStatus,
        rxExpirationDate: payload.rxExpirationDate,
        showPrice: shouldRxShowPrice(payload.rxCardStatus),
        isBirdiSelect: payload.isBirdiSelect,
        showRefillsAvailable: shouldShowRefillsAvailable(payload.realRxCardStatus),
        autoRefillEligibleStatus: isStatusEligibleForAutoRefill(payload.realRxCardStatus),
        showBirdiSelectFlag: shouldRxDisplayBirdiSelectFlag(payload.isBirdiSelect, planAlias, isOnDemandPlan),
        details: details,
        statuses: statuses,
        footNote: getRxStatusFooter(payload, t)
    };

    return props;
};
