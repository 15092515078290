/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */

export enum RX_AVAILABLE_FLOWS {
    MEDICINE_CABINET = 'MEDICINE_CABINET',
    AUTO_REFILL = 'AUTO_REFILL',
    EASY_REFILL = 'EASY_REFILL'
}

export enum RX_STATUS {
    NEW_RX = 'NEW_RX',
    REFILL_AVAILABLE = 'REFILL_AVAILABLE',
    IN_CART = 'IN_CART',
    ORDERED = 'ORDERED',
    EXPIRED = 'EXPIRED',
    OUT_OF_REFILLS = 'OUT_OF_REFILLS',
    REFILL_TOO_SOON = 'REFILL_TOO_SOON',
    TRANSFER_PENDING = 'PENDING',
    NEW_REQUEST_PENDING = 'MDO_WAITING',
    ON_HOLD = 'ON_HOLD',
    ORDER_STATUS_PENDING = 'INCOMPLETE/PENDING',
    NOT_INCLUDED = 'NOT_ON_FORMULARY',
    NO_RESPONSE_PRESCRIBER = 'MDO_CANCELLED', // to check everywhere and replace
    ASSISTANCE_NEEDED = 'ASSISTANCE_NEEDED',
    SHIPPED = 'SHIPPED'
}

export enum RX_CURRENT_STEP_DESCRIPTION {
    UNKNOWN = 0,
    PROCESSING = 1,
    FILLING_IN_PHARMACY = 2,
    PACKAGED = 3,
    SHIPPED = 4,
    OUT_FOR_DELIVERY = 5,
    DELIVERED = 6
}

export enum RX_SUB_STATUS {
    PLEASE_CALL_BIRDI = 7,
    AUTH_REQUIRED = 8,
    WAITING_ON_NEW_RX = 9
}

export enum RX_WEB_ELIGIBILITY_STATUS {
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    PENDING = 'PENDING',
    ELIGIBLE = 'ELIGIBLE',
    AUTH_REQ = 'AUTH_REQ',
    MDO_CANCELLED = 'MDO_CANCELLED',
    MDO_WAITING = 'MDO_WAITING'
}

export enum RX_STATUS_PAYLOAD {
    PROFILED = 'Profiled',
    OPEN = 'Open'
}

export enum RX_ORDER_STATUS {
    NONE = 'NONE',
    CANCELLED = 'CANCELLED',
    SHIPPED = 'SHIPPED',
    RETURN_PENDING = 'RETURN PENDING',
    INCOMPLETE_PENDING = 'INCOMPLETE/PENDING'
}

export enum RX_ORDER_LINE_QUEUE_STATUS {
    SHIPPED = 'SHIPPED',
    NOT_FOUND = 'NOTFOUND',
    OPEN = 'OPEN'
}

export enum RX_SUB_STATUS_LABEL {
    DELIVERED = 'Delivered',
    SHIPPED = 'Shipped',
    OUT_FOR_DELIVERY = 'Out for Delivery'
}
