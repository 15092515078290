import { GLOBAL_PHONE_NUMBER } from 'gatsby-env-variables';
import sanitizeHtml from 'sanitize-html';

export interface getPhoneNumberProps {
    isEnd?: boolean;
    isPlainText?: boolean;
}

export function getPhoneNumber({ isEnd = false, isPlainText = false }: getPhoneNumberProps): string {
    // Ensure GLOBAL_PHONE_NUMBER is defined and is a string, otherwise return a blank space
    const phoneNumber = GLOBAL_PHONE_NUMBER ? GLOBAL_PHONE_NUMBER.processed : '';

    if (isPlainText) {
        const formattedText = phoneNumber.replace(/<\/?p>/g, '').replace(/<\/?strong>/g, '');

        return formattedText;
    } else {
        // Define the regex pattern to remove <p> tags
        const pattern = /<\/?p>/g;

        // Remove <p> tags from the phoneNumber
        let formattedText = phoneNumber.replace(pattern, '');

        // Sanitize the resulting HTML to allow only <strong> tags with class attributes
        formattedText = sanitizeHtml(formattedText, {
            allowedTags: ['strong'],
            allowedAttributes: {
                strong: ['class']
            }
        });

        // Append a period if isEnd is true, otherwise append nothing
        formattedText += isEnd ? '.' : '';

        return formattedText;
    }
}
