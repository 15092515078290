import {
    CaregiverAddressesResponse,
    EasyRefillCaregiverPaymentData,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPaymentCardDataResponse
} from 'types/easy-refill';

import { EasyRefillState } from './easy-refill.reducer';

// DRX-2008: This helper was neccesary cause the cartOBject was different from the authenticated flow.
// TODO: Clarify with the Backend team this implementation.

/**
 * Returns the main cart from a list of carts based on the EpostPatientNum.
 *
 * @param {Array<cartState['cart']>} carts - The list of carts to search through.
 * @param {string | undefined} EpostPatientNum - The EpostPatientNum to match against.
 * @return {cartState['cart'] | undefined} The main cart that matches the EpostPatientNum, or undefined if no match is found.
 */
export const getEasyRefillMainCart = (carts: EasyRefillState['cart'], epostPatientNum: string | undefined) => {
    const mainCart = carts?.filter((cart) => {
        return epostPatientNum === cart.epostPatientNum;
    });

    return mainCart && mainCart[0];
};

/**
 * Calculates the total order value for all items in the given carts.
 *
 * @param {cartState['cart']} carts - The array of carts containing the items.
 * @return {number} The total order value.
 */
export const getEasyRefillAllOrderTotal = (carts: EasyRefillState['cart']) => {
    return carts?.reduce((total, cart) => {
        return total + Number(cart?.order?.orderTotal);
    }, 0);
};

/**
 * Calculates the total number of items in all the carts.
 *
 * @param {cartState['cart'][]} carts - The list of carts.
 * @return {number} The total number of items in all the carts.
 */
export const getEasyRefillAllItemsTotal = (carts: EasyRefillState['cart']) => {
    return carts?.reduce((total, cart) => {
        return total + cart?.order.refillRxs?.length;
    }, 0);
};

/**
 * Adapts the CaregiverPaymentMethods Object.
 *
 * @param {response} - The Caregiver payment methods
 */
export function adaptCaregiverPaymentsResponse(
    response: EasyRefillCaregiverPaymentData
): GetEasyRefillPaymentCardDataResponse {
    const adaptedResponse: GetEasyRefillPaymentCardDataResponse = {
        messageStatus: response.messageStatus,
        messageText: response.messageText,
        paymentCards: []
    };

    if (response.paymentCards.familyCards && response.paymentCards.familyCards.length > 0) {
        const caregiverPaymentMethods = response.paymentCards.familyCards[0];
        if (caregiverPaymentMethods.paymentCards && caregiverPaymentMethods.paymentCards.results) {
            adaptedResponse.paymentCards = adaptedResponse.paymentCards.concat(
                caregiverPaymentMethods.paymentCards.results
            );
        }
    }

    return adaptedResponse;
}

/**
 * Adapts the CaregiverAddresses Object.
 *
 * @param {response} - The Caregiver Address object
 */
export function adaptCaregiverAddresessResponse(
    response: CaregiverAddressesResponse
): GetEasyRefillAddressDataResponse {
    const adaptedResponse: GetEasyRefillAddressDataResponse = {
        messageStatus: response.messageStatus,
        messageText: response.messageText,
        addresses: []
    };

    if (response.data.length > 0) {
        const caregiverAddresess = response.data[0].address.results;
        adaptedResponse.addresses = adaptedResponse.addresses.concat(caregiverAddresess);
    }

    return adaptedResponse;
}

/**
 * Validate if any user has rxs available to access to the easy refill flow..
 *
 * @param data The users inside the response of GetEasyRefillPatientFamilyData
 */
export function hasRxInFamily(data: GetEasyRefillPatientDataResponse[]) {
    for (const patient of data) {
        if (patient.rxResults && patient.rxResults.length > 0) {
            return true;
        }
    }
    return false;
}
