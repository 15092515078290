import {
    RX_ORDER_LINE_QUEUE_STATUS,
    RX_STATUS,
    RX_STATUS_PAYLOAD,
    RX_WEB_ELIGIBILITY_STATUS
} from 'enums/prescription';
import moment from 'moment';

import { ProfileObjectPayload } from 'state/account/account.services';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';

import { RxDetails } from 'types/prescription';

import storageHelper from './storageHelper';

export function addDays(date: string, days: number): Date {
    const origDate = new Date(date);
    origDate.setDate(origDate.getDate() + days);
    return origDate;
}

export function isShipStatusExpired(shipDate: string): boolean {
    if (!shipDate) return false;
    const showShippedStatusDays = 14;
    const shippedExpirationDate = addDays(shipDate, showShippedStatusDays);
    const today = new Date(Date.now());
    return !!(shippedExpirationDate < today);
}

/**
 * Function to check the order is on hold or ordered/shipped state
 * @param orderLineQueueStatus - Order Line queue status of prescription
 * @returns boolean
 */
export const isOrderLineQueued = (orderLineQueueStatus: RX_ORDER_LINE_QUEUE_STATUS): boolean => {
    if (!orderLineQueueStatus) {
        return true;
    }
    return [
        RX_ORDER_LINE_QUEUE_STATUS.SHIPPED,
        RX_ORDER_LINE_QUEUE_STATUS.NOT_FOUND,
        RX_ORDER_LINE_QUEUE_STATUS.OPEN
    ].includes(orderLineQueueStatus);
};

export const isRxOrderedInProgress = (rx: RxDetails) => {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        (rx.itemInWorkflow === true ||
            (isOrderLineQueued(rx.orderLineQueueStatus) &&
                rx.itemInWorkflow === false &&
                !isShipStatusExpired(rx.lastFillDate)))
    );
};

export const isRxOnHold = (rx: RxDetails) => {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        rx.itemInWorkflow === false &&
        rx.orderQueueStatus === 'HOLD'
    );
};

export function isRxExpired(expirationDate: string): boolean {
    const today = moment(new Date()).format('MM/DD/YYYY');
    return moment(expirationDate).isBefore(today, 'day');
}

export function isRxCloseToExpire(expirationDate: string): boolean {
    const inputDate = moment(expirationDate, 'MM/DD/YYYY');
    const today = moment();
    if (inputDate.isAfter(today) && inputDate.diff(today, 'days') <= 20) {
        return true;
    }
    return false;
}

export function isRxExpiresToday(expirationDate: string): boolean {
    const inputDate = moment(expirationDate, 'MM/DD/YYYY');
    const today = moment();
    return inputDate.isSame(today, 'day');
}

export function isRxRefillTooSoonStatus(rx: RxDetails): boolean {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        rx.nextFillDate !== undefined &&
        rx.itemInWorkflow === false &&
        (isShipStatusExpired(rx.lastFillDate) as boolean)
    );
}

export function isRxRefillAvailableStatus(rx: RxDetails): boolean {
    return rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE && rx.rxStatus !== RX_STATUS_PAYLOAD.PROFILED;
}

export function isRxOutOfRefillsStatus(rx: RxDetails): boolean {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ &&
        !isRxExpired(rx.rxExpirationDate) &&
        Number(rx.fillsRemaining) === 0
    );
}

export function isRxExpiredStatus(rx: RxDetails): boolean {
    return rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ && isRxExpired(rx.rxExpirationDate);
}

export function isRxNew(rx: RxDetails): boolean {
    const rxIdentifier = rx.rxNumber;
    const { prescriptions } = storageHelper.local.getNewRxs();

    if (prescriptions) {
        return prescriptions.includes(rxIdentifier);
    }

    return false;
}

export function hasPlanAutoRefillFlag(epostNum: string, profileObject: ProfileObjectPayload): boolean {
    if (profileObject && epostNum === profileObject.epostPatientNum) {
        return profileObject.patientPlanAutoRefillFlag;
    }

    if (profileObject && profileObject.isCaregiver) {
        const dependentAccount = profileObject.dependents.find((dependent) => dependent.epostPatientNum === epostNum);
        return dependentAccount ? dependentAccount.patientPlanAutoRefillFlag : false;
    }

    return false;
}

// Check if the price exists for the given prescription number.
export const findPriceMatch = (rxNumber: string, drugDiscountPrices: DrugWithDiscountPrice[], zipcode?: string) => {
    // Check if the price exists for the given prescription number.
    const priceMatches = drugDiscountPrices.find((item) => {
        return zipcode ? item.rxNumber === rxNumber && item.zipCode === zipcode : item.rxNumber === rxNumber;
    });

    return priceMatches ? priceMatches.price : '';
};

// Function that validates whether an rx status should show the
// add to cart button enabled
export const IsAddToCartCTAEnabled = (status: RX_STATUS) => {
    return [RX_STATUS.NEW_RX, RX_STATUS.REFILL_AVAILABLE, RX_STATUS.EXPIRED, RX_STATUS.OUT_OF_REFILLS].includes(status);
};

export const debugRxCard = (card: RxDetails) => {
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log('rxCardStatus:', card.rxCardStatus);
    console.log('realRxCardStatus:', card.realRxCardStatus);
    console.log(card);
    console.groupEnd();
};
