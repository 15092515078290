import { graphql } from 'gatsby';
import React from 'react';

import AlertBanner from 'components/alert-banner/alert-banner.component';
import { AlertProps } from 'components/alert-banner/alert-banner.props';

export default function PageAlertProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.PageAlertProviderFragment;
    sectionIndex: number;
}) {
    const alertBannerProps: AlertProps = {
        message: paragraph.field_alert_text,
        link: paragraph.field_alert_link?.title,
        linkLocation: paragraph.field_alert_link?.uri,
        variant: paragraph.field_alert_priority_level ? paragraph.field_alert_priority_level : 'alert-dark',
        permanent: false,
        dismissible: false,
        className: 'border-0',
        sectionIndex: sectionIndex,
        showResponsive: paragraph.field_alert_show_responsive
    };

    return <AlertBanner {...alertBannerProps} />;
}

export const query = graphql`
    fragment PageAlertProvider on paragraph__page_alert_banner {
        id
        field_alert_link {
            title
            uri
        }
        field_alert_text
        field_alert_priority_level
        field_alert_show_responsive
    }
`;
