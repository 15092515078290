import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { getPhoneNumber } from 'util/globalVariables';

export interface BirdiModalErrorContentProps {
    description: React.ReactNode;
    ctaText: string;
    onClose: () => void;
}

const BirdiModalErrorContent = ({ description, ctaText, onClose }: BirdiModalErrorContentProps) => {
    const { t } = useTranslation();

    return (
        <div className="modal-error-content">
            <div className="modal-error-content__content">
                <h4>{description}</h4>

                <div className="modal-error-content__foot-notes">
                    <ToastBox variant="descriptive">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('modals.errorAssistanceFootnote', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    }),
                                    {
                                        allowedTags: ['strong']
                                    }
                                )
                            }}
                        />
                    </ToastBox>
                </div>
                <Button label={ctaText} type="button" variant="primary" onClick={onClose} />
            </div>
        </div>
    );
};

export default BirdiModalErrorContent;
