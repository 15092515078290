import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'ui-kit/spinner/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalHeader from 'components/birdi-modal/birdi-modal-header';

import { membershipBirdiSelectFormularyRoutine } from 'state/membership/membership.routines';
import {
    membershipBirdiSelectFomularySelector,
    membershipIsLoadingDetailsSelector,
    membershipIsOnDemandSelector
} from 'state/membership/membership.selector';

// Splitting Header and body because the discount-drug-modal.tsx file
// use them separately, however that logic will be replaced in a re-design
// for the cash price formulary in transfer Rx flow
export const BirdiSelectFormularySimpleHeader = () => {
    const { t } = useTranslation();
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);

    return (
        <>
            <BirdiModalHeader
                title={<h1 className="birdi-select-header">{t('pages.drugsList.membership')}</h1>}
                note={
                    isOnDemandPlan ? (
                        <div className="birdi-select-note">
                            <ToastBox variant="default" icon="default">
                                <p className="m-0">{t('pages.drugsList.membershipNote')}</p>
                            </ToastBox>
                        </div>
                    ) : undefined
                }
                // eslint-disable-next-line react/no-children-prop
                children={undefined}
            />
        </>
    );
};

export const BirdiSelectFormularySimpleBody = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const birdiSelectFormulary = useSelector(membershipBirdiSelectFomularySelector);
    const isLoading = useSelector(membershipIsLoadingDetailsSelector);

    useEffect(() => {
        dispatch(membershipBirdiSelectFormularyRoutine.trigger());
    }, [dispatch]);

    return (
        <>
            <table className="table table-striped table-bordered table-sticky-head">
                <thead className="thead-dark sticky-top">
                    <tr>
                        <th>
                            <Row>
                                <Col sm={12} lg={6}>
                                    Drug Name
                                </Col>
                                <Col sm={12} lg={6}>
                                    Formats / Strengths
                                </Col>
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {birdiSelectFormulary.drugList.map((drug, i) => (
                        <tr key={`birdi-select-formulary-${i}`}>
                            <td>
                                <Row key={i}>
                                    <Col sm={12} lg={6} className="text-uppercase">
                                        {drug.drugName}
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <Row>
                                            <Col sm={12} lg={6}>
                                                {drug.drugForm}
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <Row>
                                                    <Col>
                                                        {drug.drugStrength} {drug.drugStrengthUnit}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Spinner isVisible={isLoading} t={t} />
        </>
    );
};
