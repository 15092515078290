import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import { PrescriptionObjectPayload } from 'types/prescription';

import axiosClient from 'util/axiosClient';

export interface ToggleAutoFillRequest {
    RxNumber: string;
    AutoFillToggle: boolean;
    isRenew?: boolean;
}

export interface ToggleAutoFillResponse {
    Id: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

// api/Prescriptions/GetStatusForRxInProgress/{rxNumber}
// Response
export interface RxStatusResponse {
    RxInProgressStatus?: number;
    RxInProgressDescription?: string;
    CarrierName?: null;
    TrackingNumber?: null;
    EstimatedDeliveryDate?: null;
    DeliveredDate?: null;
    CurrentStep?: number;
    MaxStep?: number;
    messageErrorText?: string;
    messageStatus?: boolean;
    rxNumber?: string;
    loading?: boolean;
}

/**
 * Anything related to Medicine Cabinet in the system.
 */
export default class MedicineCabinetService {
    static allRxs() {
        return {
            get: async (epostNumFamilyMember?: string): Promise<PrescriptionObjectPayload[]> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/v2/Prescriptions/AllRxs${
                        epostNumFamilyMember ? `/${epostNumFamilyMember}` : ''
                    }`
                );
                return response.data;
            }
        };
    }
    static toggleAutofillForRx() {
        return {
            post: async ({ RxNumber, AutoFillToggle }: ToggleAutoFillRequest): Promise<ToggleAutoFillResponse> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/Prescriptions/ToggleAutoFill`, {
                    RxNumber,
                    AutoFillToggle
                });
                return response.data;
            }
        };
    }
    static getStatusForRx() {
        return {
            get: async (
                rxNumber: string,
                epostNumFamilyMember?: string
            ): Promise<Omit<RxStatusResponse, 'rxNumber'>> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/Prescriptions/GetStatusForRxInProgress/${rxNumber}${
                        epostNumFamilyMember ? `/${epostNumFamilyMember}` : ''
                    }`
                );
                return response.data;
            }
        };
    }
}
