import { AutoRefillFlag, AutoRefillPropsSearch } from 'types/auto-refill';

import storageHelper from 'util/storageHelper';

export const validateAutoRefillModalDisplay = (
    isAccountEligible: boolean,
    isCAResident: boolean,
    newAddressState: string | null = null
) => {
    const autoRefillToggleModalStorage = storageHelper.local.getAutoRefillToggleFlag();
    // Validate against local storage to see if we have shown the modal before
    const shouldShowModal: boolean =
        Object.keys(autoRefillToggleModalStorage).length === 0 ||
        new Date() >= new Date(autoRefillToggleModalStorage.expiration);
    if (newAddressState) {
        // If user is changing from california to non california user and is eligible
        return isCAResident && newAddressState !== 'CA' && isAccountEligible && !!shouldShowModal;
    }
    // Default: If user is not california but is eligible
    return !isCAResident && isAccountEligible && shouldShowModal;
};

// Function that validates if terms and conditions modal should be displayed
// when turning auto refill on / off at the prescription card.
export const validateAutoRefillTermsAndConditionsModalDisplay = (
    isTogglingOn: boolean,
    isAnyRxHasAutoRefill = false,
    isPlanAutoRefillOn = false,
    isCaliforniaUser = false
) => {
    // Show the modal only when we are turning on auto refill
    if (!isTogglingOn) return false;

    // If user is in california user
    // or is not california user and hasn't turned on auto refill at the account level
    // and any rx has autorefill
    return isCaliforniaUser || (!isCaliforniaUser && !isPlanAutoRefillOn && !isAnyRxHasAutoRefill);
};

// For context, the structure of the API response, returns the auto refill
// properties in a different array, so we have this function to find the results
// of a certain property gotten.
const getAutoRefillProperty = ({
    autoRefillFlags,
    rxNumber,
    property = 'autoRefillEligible'
}: AutoRefillPropsSearch) => {
    if (autoRefillFlags.length <= 0) {
        return false;
    }

    const foundRx = autoRefillFlags.find((rx: AutoRefillFlag) => rx.rxNumber === rxNumber);
    if (!foundRx) return false;

    return foundRx[property];
};

// Function that founds in the easy refill or auto refill response if auto-refill is enabled for an rx
export const rxAutoRefillEnabled = (autoRefillFlags: AutoRefillFlag[], rxNumber: string) =>
    getAutoRefillProperty({ autoRefillFlags, rxNumber, property: 'autoRefillEnabled' });

// Function that founds in the easy refill or auto refill response if auto-refill is eligible for an rx
export const rxAutoRefillEligible = (autoRefillFlags: AutoRefillFlag[], rxNumber: string) =>
    getAutoRefillProperty({ autoRefillFlags, rxNumber, property: 'autoRefillEligible' });
