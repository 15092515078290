/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
export enum BIRDI_PLANS {
    BRD_01 = 'BRD01',
    BRD_02 = 'BRD02'
}

export enum PLAN_COVERAGE_TYPE {
    PRIMARY = 'PRIMARY',
    INACTIVE = 'INACTIVE'
}
