import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { ButtonVariantProps } from 'ui-kit/button/button.props';

export interface ModalCTAButton {
    label: string;
    variant?: typeof ButtonVariantProps;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    async?: boolean;
    dataGALocation?: string;
}

export interface OpenModalPayload {
    className?: string;
    contentClassName?: string;
    headerContent?: JSX.Element;
    bodyContent: JSX.Element;
    ctas?: ModalCTAButton[];
    ctaOrientation?: 'horizontal' | 'vertical';
    showClose: boolean;
    backdrop?: boolean | 'static' | undefined;
    onClose?: () => void;
    size?: 'sm' | 'lg' | 'xl';
    type?: 'default' | 'danger' | 'primary';
    footerStyle?: 'sticky' | 'reverse';
    isModalCentered?: boolean;
    keyboard?: boolean | undefined;
}

export interface BirdiModalState {
    isOpen: boolean;
    headerContent?: JSX.Element;
    bodyContent?: JSX.Element;
    className?: string;
    contentClassName?: string;
    ctas: ModalCTAButton[];
    showClose: boolean;
    onClose?: () => void;
    isBusy?: boolean;
    backdrop: boolean | 'static' | undefined;
    size?: 'sm' | 'lg' | 'xl';
    type?: 'default' | 'danger' | 'primary';
    footerStyle?: 'sticky' | 'reverse';
    ctaOrientation?: 'horizontal' | 'vertical';
    isModalCentered?: boolean;
    keyboard?: boolean | undefined;
}

const initialState: BirdiModalState = {
    isOpen: false,
    className: undefined,
    contentClassName: undefined,
    headerContent: undefined,
    bodyContent: undefined,
    ctas: [],
    showClose: true,
    onClose: undefined,
    isBusy: false,
    backdrop: undefined,
    size: 'xl',
    type: 'default',
    footerStyle: undefined,
    ctaOrientation: 'horizontal',
    isModalCentered: false,
    keyboard: undefined
};
const birdiModalSlice = createSlice({
    name: 'birdi-modal',
    initialState,
    reducers: {
        openModal: (state, { payload }: PayloadAction<OpenModalPayload>) =>
            produce(state, (draftState) => {
                draftState.isOpen = true;
                draftState.onClose = payload.onClose;
                draftState.headerContent = payload.headerContent;
                draftState.bodyContent = payload.bodyContent;
                draftState.className = payload.className;
                draftState.contentClassName = payload.contentClassName;
                draftState.ctas = payload.ctas;
                draftState.showClose = payload.showClose;
                draftState.backdrop = payload.backdrop;
                draftState.isBusy = false;
                draftState.size = payload.size;
                draftState.type = payload.type;
                draftState.footerStyle = payload.footerStyle;
                draftState.ctaOrientation = payload.ctaOrientation;
                draftState.isModalCentered = payload.isModalCentered;
                draftState.keyboard = payload.keyboard;
            }),
        closeModal: (state, _) =>
            produce(state, (draftState) => {
                draftState.isOpen = false;
                draftState.isBusy = false;
            }),
        setBusyModal: (state, action: PayloadAction<boolean>) =>
            produce(state, (draftState) => {
                draftState.isBusy = action.payload;
            })
    }
});

export const { openModal, closeModal, setBusyModal } = birdiModalSlice.actions;
export default birdiModalSlice.reducer;
