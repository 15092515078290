import { PayloadAction } from '@reduxjs/toolkit';
import { AWS_CONFIG_POOLID, AWS_CONFIG_REGION } from 'gatsby-env-variables';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
    accountAcCodeSelector,
    accountHasInsuranceSelector,
    accountIsLoggedInSelector,
    accountProfileAddressesSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import AccountService, { ProfileObjectPayload } from 'state/account/account.services';
import { addTransferPrescriptionMemberPlanSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import {
    autoRefillAcCodeSelector,
    autoRefillAccountHasInsuranceSelector,
    autoRefillAdressesSelector,
    autoRefillDefaultAddressSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyPlansMapSelector,
    autoRefillIsCaregiverSelector
} from 'state/auto-refill/auto-refill.selectors';
import { cartItemsSelector, cartOrderShippingAddressSelector } from 'state/cart/cart.selectors';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import {
    drugDescriptionRoutine,
    drugDetailsLookupRoutine,
    drugDiscountPriceRoutine,
    drugFormLookupRoutine,
    drugListRoutine,
    drugLookupRoutine
} from 'state/drug/drug.routines';
import { drugsWithdisccountSelector } from 'state/drug/drug.selectors';
import DrugService, {
    drugDescriptionRoutinePayload,
    drugDetailsRoutinePayload,
    drugDiscountPriceRoutinePayload,
    drugListRoutinePayload,
    drugLookupRoutinePayload
} from 'state/drug/drug.services';
import {
    easyRefillAccountHasInsuranceSelector,
    easyRefillAddressesSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillFamilyPlansMapSelector,
    easyRefillIsCaregiverSelector,
    easyRefillOrderAddressSelector,
    easyRefillPlanAliasSelector
} from 'state/easy-refill/easy-refill.selectors';
import { familyProfileFamilyPlansMapSelector } from 'state/family-profile/family-profile.selectors';

import { Address } from 'types/easy-refill';
import { RefillRxs } from 'types/order-prescription';

import { birdiPriceRxLineErrorCodes, RxLineErrorCode } from 'util/cart';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

export default function* drugSaga() {
    yield takeLatest(drugListRoutine.TRIGGER, function* (action: PayloadAction<drugListRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugList().get,
            data: {
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugListRoutine.success({ insuranceName: insuranceName, formulary: response }));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugListRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugLookupRoutine.TRIGGER, function* (action: PayloadAction<drugLookupRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        const selectedMemberPlan: number = yield select(addTransferPrescriptionMemberPlanSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugLookup().get,
            data: {
                drugName: action.payload.drugName,
                clientAcCode: selectedMemberPlan || insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugLookupRoutine.success(response));
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugDetailsLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug details.
        yield baseEffectHandler({
            service: DrugService.drugDetailsLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugDetailsLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugDetailsLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugFormLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        const selectedMemberPlan: number = yield select(addTransferPrescriptionMemberPlanSelector);

        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug forms.
        yield baseEffectHandler({
            service: DrugService.drugFormLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: selectedMemberPlan || insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugFormLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugFormLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(
        drugDiscountPriceRoutine.TRIGGER,
        function* (action: PayloadAction<drugDiscountPriceRoutinePayload>) {
            const { prescriptions, forceBirdiInsurance, location, unAuthArea } = action.payload;

            const isLoggedIn: boolean = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const accessType = isLoggedIn ? 'logged' : unAuthArea === 'auto-refill' ? 'autoRefill' : 'easyRefill';

            const selectors = {
                logged: {
                    insuranceName: accountAcCodeSelector,
                    hasInsurance: accountHasInsuranceSelector,
                    isCaregiver: accountProfilIsCaregiverSelector,
                    addresses: accountProfileAddressesSelector,
                    familyPlansMap: familyProfileFamilyPlansMapSelector,
                    epostPatientNum: accountProfilEPostPatientNumSelector,
                    orderAddress: cartOrderShippingAddressSelector
                },
                easyRefill: {
                    insuranceName: easyRefillPlanAliasSelector,
                    hasInsurance: easyRefillAccountHasInsuranceSelector,
                    isCaregiver: easyRefillIsCaregiverSelector,
                    addresses: easyRefillAddressesSelector,
                    familyPlansMap: easyRefillFamilyPlansMapSelector,
                    epostPatientNum: easyRefillEpostPatientNumSelector,
                    orderAddress: easyRefillOrderAddressSelector
                },
                autoRefill: {
                    insuranceName: autoRefillAcCodeSelector,
                    hasInsurance: autoRefillAccountHasInsuranceSelector,
                    isCaregiver: autoRefillIsCaregiverSelector,
                    addresses: autoRefillAdressesSelector,
                    familyPlansMap: autoRefillFamilyPlansMapSelector,
                    epostPatientNum: autoRefillePostPatientNumberSelector,
                    orderAddress: autoRefillDefaultAddressSelector
                }
            };

            const hasInsurance: boolean = yield select(selectors[accessType].hasInsurance);
            const insuranceName: string = yield select(selectors[accessType].insuranceName);
            const isCaregiver: boolean = yield select(selectors[accessType].isCaregiver);
            const addresses: Address[] = yield select(selectors[accessType].addresses);
            const familyPlansMap: Record<string, string> = yield select(selectors[accessType].familyPlansMap);
            const epostPatientNum: string = yield select(selectors[accessType].epostPatientNum);
            const orderAddress: Address = yield select(selectors[accessType].orderAddress);
            const drugsPriceLookupHistory: DrugWithDiscountPrice[] = yield select(drugsWithdisccountSelector);
            const selectedMemberPlan = yield select(addTransferPrescriptionMemberPlanSelector);

            const currentCartItem: RefillRxs[] = yield select(cartItemsSelector);

            const defaultAddress = addresses.find((address) => address.defaultShip);
            const shippingAddress = orderAddress || defaultAddress;

            // This action is always called following medicineCabinetGetAllP rescriptions.trigger,
            // but that action does not *always* run after the profileObject has been loaded.
            // So just check to make sure that profileObject exists before proceeding.
            // Whatever is dispatching this action is responsible for making sure that it
            // does so after the profileObject has been loaded.
            // Also, don't run this action if the user does have insurance.
            if (isLoggedIn && (!profileObject || (hasInsurance && !forceBirdiInsurance))) {
                return;
            }

            // Fetch the AWS credentials.
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            const drugsForPricing = prescriptions.map((item) => {
                const drugCode =
                    item.dispensedProductNumber !== ''
                        ? item.dispensedProductNumber
                        : item.writtenProductNumber !== ''
                        ? item.writtenProductNumber
                        : '';

                // We default the plan name on the insurance name
                let clientAcCode = insuranceName;

                // If we directly receive a selectedMemberPlan we use this value instead
                if (selectedMemberPlan) {
                    clientAcCode = selectedMemberPlan;
                }

                // But if we don't receive a plan and the user is a caregiver
                // we look into the family members array the plan of the patient.
                if (!selectedMemberPlan && isCaregiver && epostPatientNum !== item.epostPatientNum) {
                    clientAcCode = familyPlansMap[item.epostPatientNum] || 'BRD01';
                }

                // Birdi Price selection for insured accounts
                // Find insurance rxLineErrors
                const findRxLineError = currentCartItem?.find((f) => f.rxNumber === item.rxNumber)?.rxLineError;
                // 40, 65, 70 errors
                const hasCashPriceErrors = birdiPriceRxLineErrorCodes.includes(findRxLineError as RxLineErrorCode);
                // change the clientAcCode to BRD01
                if (hasInsurance && hasCashPriceErrors) {
                    clientAcCode = 'BRD01';
                }
                // We return the drugs information to the final array
                return {
                    drugCode,
                    quantity: item.fillQuantity,
                    daysSupply: item.fillDaysSupply,
                    clientAcCode: clientAcCode,
                    memberNumber: isLoggedIn ? profileObject.cardholderID : '',
                    location: location,
                    zipCode: shippingAddress.zipcode,
                    gpi: item.genericProductIndicator,
                    rxNumber: item.rxNumber
                };
            });

            // Call the drug discount price lookup for each of the drugs in parallel.
            const simultaneousRequests = 9;
            for (let i = 0; i < drugsForPricing.length; i += simultaneousRequests) {
                const rxSlice = drugsForPricing.slice(i, i + simultaneousRequests);
                yield all(
                    rxSlice.map((item) => {
                        const { rxNumber, ...data } = item;

                        const foundInHistory = drugsPriceLookupHistory.find((drug) => {
                            return (
                                drug.rxNumber &&
                                drug.rxNumber === rxNumber &&
                                drug.planAlias === data.clientAcCode &&
                                drug.zipCode === data.zipCode
                            );
                        });

                        // We validate if we haven't gotten a price already for this drug
                        // TODO: When refactoring, we need to consider that we may have history of
                        // api calls of drugs in progress, that probably hasn't filled the history
                        // so we need to implement a method that tell us that "the look up in progress"
                        // to avoid unnecessary API calls
                        if (foundInHistory) return null;

                        // The following throws a TypeScript error ("No overload
                        // matches this call"). Not convinced that this is a legitimate
                        // issue.
                        // @ts-expect-error: Reasoning above.
                        return call(baseEffectHandler, {
                            service: DrugService.drugDiscountPriceLookup().get,
                            data,
                            *onResponse(response) {
                                yield put(
                                    drugDiscountPriceRoutine.success({
                                        response: response,
                                        rxNumber: rxNumber,
                                        zipCode: data.zipCode
                                    })
                                );
                                const { onSuccess } = action.payload;
                                if (onSuccess)
                                    onSuccess({ response: response, rxNumber: rxNumber, zipCode: data.zipCode });
                            },
                            *onError(error) {
                                yield put(drugDiscountPriceRoutine.failure(rxNumber));
                                const { onFailure } = action.payload;
                                if (onFailure) onFailure({ response: error });
                                // Log the error.
                                if (error.response?.data) {
                                    // This is an attempt to catch errors passed back from the API.
                                    if (error.response?.data?.status) {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.status
                                        );
                                    } else {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.message
                                        );
                                    }
                                } else if (error.message) {
                                    // This is an attempt to catch basic network errors.
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error.message);
                                } else {
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error);
                                }
                            }
                        });
                    })
                );
            }
        }
    );
    yield takeLatest(drugDescriptionRoutine.TRIGGER, function* (action: PayloadAction<drugDescriptionRoutinePayload>) {
        try {
            //CALL BEFORE ROUTINE
            yield put(drugDescriptionRoutine.request());

            // Get AWS credentials.
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            // HANDLERS
            yield baseEffectHandler({
                service: DrugService.drugDescriptionLookup().get,
                data: action.payload,
                *onResponse(response) {
                    if (response.htmlDesc) {
                        yield put(drugDescriptionRoutine.success(response));
                        const { onSuccess } = action.payload;
                        // CALLBACK SUCCESS
                        if (onSuccess) onSuccess(response);
                    } else {
                        yield put(drugDescriptionRoutine.failure());
                        const { onFailure } = action.payload;
                        // CALLBACK ERROR
                        if (onFailure) onFailure();
                    }
                },
                *onError(error) {
                    if (error.response?.data?.message) {
                        TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.response.data.message);
                    } else if (error.message) {
                        TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.message);
                    }
                    // CALLBACK ERROR
                    yield put(drugDescriptionRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        } catch (error) {
            // CALLBACK ERROR
            yield put(drugDescriptionRoutine.failure());
        }
    });
}
