import format from 'date-fns/format';

import { CaregiverProfile, FamilyManagementStatus, FamilyMemberAccountType } from 'types/family-management';

import { Dependent } from './family-profile.reducers';

type DependentStatus = Extract<
    FamilyManagementStatus,
    'invitePending' | 'fullAccess' | 'partialAccess' | 'declined' | 'expired'
>;

type CaregiverStatus = Extract<
    FamilyManagementStatus,
    'responsePending' | 'fullAccess' | 'partialAccess' | 'declined' | 'expired'
>;

export const accountStatusMap: Map<number, DependentStatus> = new Map([
    [0, 'invitePending'],
    [1, 'fullAccess'],
    [2, 'partialAccess'],
    [3, 'declined'],
    [4, 'expired']
]);

export const accountTypeMap: Map<number, FamilyMemberAccountType> = new Map([
    [0, 'caregiver'],
    [1, 'Child'],
    [2, 'adult'],
    [3, 'pet']
]);

export const caregiverStatusMap: Map<number, CaregiverStatus> = new Map([
    [0, 'responsePending'],
    [1, 'fullAccess'],
    [2, 'partialAccess'],
    [3, 'declined'],
    [4, 'expired']
]);

// DRX-1785: This function returns the specific date based on the account status.
export function getLastUpdateDate(caregiver: CaregiverProfile): string {
    const accountStatus = caregiverStatusMap.get(caregiver.AccessLevel.AccountStatus) ?? 'responsePending';
    let lastUpdateDate;

    if (accountStatus === 'fullAccess' || accountStatus === 'partialAccess') {
        lastUpdateDate = format(new Date(caregiver.DateGrantedAccess), 'MM/dd/yyyy');
    } else {
        lastUpdateDate = format(new Date(caregiver.DateRequestedAccess), 'MM/dd/yyyy');
    }

    return lastUpdateDate;
}

export function removeDependentFromState(idToRemove: number, dependents: Array<Dependent>): Array<Dependent> {
    const itemToRemove = dependents.findIndex((item) => item.id === idToRemove);
    const dependentsCopy = [...dependents];

    if (itemToRemove !== -1) {
        dependentsCopy.splice(itemToRemove, 1);
    }

    return dependentsCopy;
}
