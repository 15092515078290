import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';
import { ALLOW_INSURED_BIRDI_PRICE, OVERRIDE_ADJUDICATION } from 'gatsby-env-variables';

import { PlansObjectPayload } from 'state/account/account.services';
import { ExtendedCartObjectPayload, OrderV2Payload } from 'state/cart/cart.services';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';

import { PlanResult } from 'types/easy-refill';
import { ExtendedRefillRxs, RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { safeParseFloat } from 'util/number';

//
// --- Types ---

type RxLineErrorCodeKey = keyof typeof RX_LINE_ERROR;
export type RxLineErrorCode = typeof RX_LINE_ERROR[RxLineErrorCodeKey];

//
// --- Constants ---

export const EXPEDITED_SHIPPING_COST = 25;
export const EXPEDITED_SHIPPING_ID = '638';
export const DEFAULT_SHIPPING_ID = '505';

/** error codes that can be returned as rx_line_error value when adding prescriptions to cart */
export const RX_LINE_ERROR = {
    NONE: 0,
    ADD_ORDER_LINE_ERROR: 1,
    ADJUDICATE_RX_ERROR: 2,
    NO_CONTRACT_WITH_PHARMACY: 40,
    NO_LONGER_COVERED: 65,
    NOT_COVERED: 70
} as const;

/** rx_line_error codes for various insurance related issues that result in birdi price being displayed */
export const birdiPriceRxLineErrorCodes: ReadonlyArray<RxLineErrorCode> = [
    RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY,
    RX_LINE_ERROR.NO_LONGER_COVERED,
    RX_LINE_ERROR.NOT_COVERED
];

//
// --- Cart Util Functions ---

export function hasAdjudicatedPrice(item: ExtendedRefillRxs, currentPrescription: RxDetails | undefined): boolean {
    if (item) {
        // TO TEST THE CART PAGES AS IF ADJUDICATION IS ON, UPDATE THE ENVIRONMENT VARIABLE
        if (OVERRIDE_ADJUDICATION) {
            return true;
        }
        //
        // rxLineError field can contain values
        //   {0 = None, 1 = AddOrderLineError, 2 = AdjudicateRxError, 40 = NoContractWithPharmacy, 65 = PatientNoLongerCovered, 70 = Not Covered};
        //   -- For now, we are treating all non-zero results as the same, this may change
        if (item.rxLineError === RX_LINE_ERROR.NONE) {
            return true;
        } else {
            if (currentPrescription) {
                if (
                    item.messageStatus &&
                    currentPrescription.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
                    currentPrescription.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return item.messageStatus;
            }
        }
    }
    return false;
}

export function isInBirdiFormulary(
    rxNumber: string,
    zipcode: string,
    drugDiscountPrices: DrugWithDiscountPrice[] | undefined
) {
    // Use the drugDiscountPrices data to determine if we have the drug in the Birdi formulary
    const inFormulary = drugDiscountPrices?.find((rx) => {
        if (rx.rxNumber === rxNumber && rx.zipCode === zipcode) {
            return true;
        }
        return false;
    });
    return inFormulary;
}

export function itemIsUsingBirdiPrice(
    item: ExtendedRefillRxs,
    accountHasInsurance: boolean,
    isMembership?: boolean,
    isBirdiCash?: boolean
) {
    // for BRD02
    if (isMembership && Number(item.birdiPrice) > 0) {
        return true;
    }

    // for BRD01
    if (isBirdiCash && !accountHasInsurance && Number(item.birdiPrice) > 0) {
        return true;
    }

    // for insuredAccounts
    if (
        accountHasInsurance &&
        ((item.birdiPlanNum && item.planNum === item.birdiPlanNum) ||
            item.showStrikeThruPricing ||
            item.showBirdiPricing)
    ) {
        return true;
    } else {
        return false;
    }
}

export function getBirdiPricePlanNum(plans: PlansObjectPayload[] | PlanResult[] | undefined) {
    if (plans) {
        const matchingPlan = plans.find((plan) => {
            if (plan.planAlias.toUpperCase() === 'BRD01') {
                return true;
            } else {
                return false;
            }
        });
        if (matchingPlan) {
            return matchingPlan.epostPlanNum;
        }
    }
    return undefined;
}

export function isItemPriceKnown(
    item: ExtendedRefillRxs,
    currentPrescription: RxDetails | undefined,
    accountHasInsurance: boolean
): boolean {
    if (
        hasAdjudicatedPrice(item, currentPrescription) ||
        (!hasAdjudicatedPrice(item, currentPrescription) &&
            accountHasInsurance &&
            ALLOW_INSURED_BIRDI_PRICE &&
            (showStrikeThruPricing(item, accountHasInsurance) || showBirdiPricing(item, accountHasInsurance)))
    ) {
        return true;
    } else {
        return false;
    }
}

export function showStrikeThruPricing(item: ExtendedRefillRxs, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        birdiPriceRxLineErrorCodes.includes(item.rxLineError as RxLineErrorCode) &&
        item?.awpPrice !== 'NA' &&
        parseFloat(item?.awpPrice) !== 0 &&
        item.birdiPrice !== 'NA' &&
        parseFloat(item?.awpPrice) > parseFloat(item.birdiPrice)
    ) {
        return true;
    } else {
        return false;
    }
}

export function showBirdiPricing(item: ExtendedRefillRxs, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        birdiPriceRxLineErrorCodes.includes(item.rxLineError as RxLineErrorCode) &&
        (item?.awpPrice === 'NA' || safeParseFloat(item?.awpPrice) <= safeParseFloat(item.birdiPrice)) &&
        item.birdiPrice !== 'NA'
    ) {
        return true;
    } else {
        return false;
    }
}

// DRX-2425: In future, we should merge the logic for both carts, maintaining the same parameters.

/**
 * Processes an array of carts returned from the API based on specific rules.
 *
 * @param carts Array of carts of the Medicine Cabinet.
 * @param accountHasInsurance Indicates whether the account has insurance.
 * @param prescriptionsObject Full prescription object with prescription details.
 * @param drugDiscountPrices Discounts obtained from the DrugPriceApi.
 * @param accountPlans Validated account plans for each cart.
 * @returns An array of processed carts based on specified rules.
 */

export function processCart(
    carts: OrderV2Payload[],
    accountHasInsurance: boolean,
    prescriptionsObject: RxDetails[] | undefined,
    drugDiscountPrices: DrugWithDiscountPrice[] | undefined,
    accountPlans: PlansObjectPayload[] | undefined,
    isMembership?: boolean,
    zipCode = ''
): ExtendedCartObjectPayload[] {
    return carts.map((cart) => {
        const extendedCart: ExtendedCartObjectPayload = {
            ...cart.Order,
            BirdiOrderNumber: cart.BirdiOrderNumber
        } as ExtendedCartObjectPayload;
        let itemHasUnknownPrice = false;

        const cartItemsObject: ExtendedRefillRxs[] = (cart.Order.refillRxs ?? []).map((item: RefillRxs) => {
            const extendedItems: ExtendedRefillRxs = {
                ...item
            } as ExtendedRefillRxs;

            return extendedItems;
        });

        /** Filter thr `accountPlans` for individual price validation*/
        const plans = accountPlans?.filter((plan) => plan.epostPatientNum === cart.EpostPatientNum);
        const birdiPricePlanNum: string | undefined = getBirdiPricePlanNum(plans);
        const isBirdiCash = plans?.find((f) => ['BRD01', 'BRD02'].includes(f.planAlias))?.planAlias ? true : false;

        if (cartItemsObject?.length > 0) {
            let calculatedOrderTotal = 0;
            const updatedRefillRxs: ExtendedRefillRxs[] = cartItemsObject.map((item: ExtendedRefillRxs) => {
                // Clone the item to avoid modifying the original object
                const updatedLineItem: ExtendedRefillRxs = { ...item };

                // Set default values for additional properties
                updatedLineItem.birdiPlanNum = birdiPricePlanNum as string;
                updatedLineItem.hasKnownPrice = false;

                // Get the current prescription details from the cart item
                const currentFullPrescription = item.prescriptionDetail;

                // Check if the prescription is in the Birdi formulary and update prices accordingly
                const prescriptionInBirdiFormulary = isInBirdiFormulary(item.rxNumber, zipCode, drugDiscountPrices);
                if (prescriptionInBirdiFormulary) {
                    updatedLineItem.awpPrice = prescriptionInBirdiFormulary.awpPrice;
                    updatedLineItem.birdiPrice = prescriptionInBirdiFormulary.price;
                    updatedLineItem.showStrikeThruPricing = showStrikeThruPricing(updatedLineItem, accountHasInsurance);
                    updatedLineItem.showBirdiPricing = showBirdiPricing(updatedLineItem, accountHasInsurance);
                }

                // Check if Birdi pricing is being used for the item
                updatedLineItem.isUsingBirdiPrice = itemIsUsingBirdiPrice(
                    updatedLineItem,
                    accountHasInsurance,
                    isMembership,
                    isBirdiCash
                );

                // Check if the item's price is known based on the current prescription
                updatedLineItem.hasKnownPrice = currentFullPrescription
                    ? isItemPriceKnown(updatedLineItem, currentFullPrescription, accountHasInsurance)
                    : false;

                // Update itemHasUnknownPrice flag
                if (!updatedLineItem.hasKnownPrice) {
                    itemHasUnknownPrice = true;
                } else {
                    // Update calculatedOrderTotal based on pricing information
                    if (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing) {
                        updatedLineItem.planNum = birdiPricePlanNum as string;
                        if (updatedLineItem.birdiPrice) {
                            calculatedOrderTotal += Number(updatedLineItem.birdiPrice);
                        }
                    } else {
                        if (updatedLineItem.patientCopay) calculatedOrderTotal += updatedLineItem.patientCopay;
                    }
                }

                // Handle birdiPriceNumeric and set finalPrice based on isUsingBirdiPrice
                let birdiPriceNumeric = Number(updatedLineItem.birdiPrice);
                if (isNaN(birdiPriceNumeric)) {
                    birdiPriceNumeric = 0;
                }
                // Final price to be displayed in most areas, birdi price or patient copay depending on isUsingBirdiPrice
                updatedLineItem.finalPrice = updatedLineItem.isUsingBirdiPrice
                    ? birdiPriceNumeric
                    : updatedLineItem.patientCopay;

                // Handle disclaimer message properties as the error statuses are treated different in the two cart,
                // we validate here in a different way. To know here whe use ´rxLineErrorString´

                if (updatedLineItem.rxLineError === RX_LINE_ERROR.ADJUDICATE_RX_ERROR) {
                    updatedLineItem.disclaimerTranslationKey = 'pages.cart.rxItemErrorMessageDiscount';
                    updatedLineItem.showDisclaimer = true;
                } else if (
                    !hasAdjudicatedPrice(updatedLineItem, currentFullPrescription) &&
                    updatedLineItem.hasKnownPrice &&
                    (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing)
                ) {
                    // Set disclaimer translation key based on rxLineError
                    updatedLineItem.disclaimerTranslationKey =
                        updatedLineItem?.rxLineError === RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY
                            ? 'pages.cart.rxItemNoPharmacyContractErrorMessage'
                            : updatedLineItem?.rxLineError === RX_LINE_ERROR.NO_LONGER_COVERED
                            ? 'pages.cart.rxItemNoLongerCoveredErrorMessage'
                            : 'pages.cart.rxItemNotCoveredErrorMessage';

                    // Set showDisclaimer based on conditions
                    updatedLineItem.showDisclaimer = true;
                } else {
                    updatedLineItem.disclaimerTranslationKey = undefined;
                    updatedLineItem.showDisclaimer = false;
                }

                return updatedLineItem;
            });

            extendedCart.itemHasUnknownPrice = itemHasUnknownPrice;
            extendedCart.extendedRefillRxs = updatedRefillRxs;
            extendedCart.orderTotal = calculatedOrderTotal.toString();
            extendedCart.FirstName = cart.FirstName;
            extendedCart.LastName = cart.LastName;
        }
        return extendedCart;
    });
}

export function isRxLineError(item: ExtendedRefillRxs) {
    return birdiPriceRxLineErrorCodes.includes(item.rxLineError as RxLineErrorCode);
}

// DRX-1658: These two functions were necessary to treat data in the frontend.
// We need to define whether the returned data will change or continue to be
// treated in the frontend.

export function lowercaseAndCapitalize(item: string): string {
    try {
        const lowercaseStr = item.toLowerCase();
        const words = lowercaseStr.split(' ');
        for (let i = 0; i < words.length; i++) {
            if (words[i] !== '') words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    } catch (error) {
        return item;
    }
}

export function ellipsify(str: string, maxCharacthers = 11): string {
    if (str?.length > maxCharacthers) {
        return str.substring(0, maxCharacthers) + '...';
    } else {
        return str;
    }
}
