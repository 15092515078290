import { ENABLE_MEMBERSHIP_BILLING } from 'gatsby-env-variables';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { noop } from 'util/function';

import { membershipPlansDetailsRoutine } from '../../state/membership/membership.routines';
import { membershipPlansDetailsSelector } from '../../state/membership/membership.selector';
import { MembershipSinglePlanDetails } from '../../types/membership';
import MembershipAvailablePlan from '../membership-available-plan/membership-available-plan.component';
import './membership-plans-tabbed-options.style.scss';

const MembershipPlansTabbedOptionsComponent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const plansDetails = useSelector(membershipPlansDetailsSelector);

    const [activeTab, setActiveTab] = useState(0);

    const savingPercentage = 15;

    const handleTabClick = (index: number) => {
        return () => setActiveTab(index);
    };

    useEffect(() => {
        dispatch(membershipPlansDetailsRoutine.trigger());
    }, [dispatch]);

    const monthlyTabClassName = activeTab === 0 ? 'tab-active' : '';
    const annuallyTabClassName = activeTab === 1 ? 'tab-active' : '';

    return (
        <div className="membership-plans-tabbed-options">
            <div className="container">
                {ENABLE_MEMBERSHIP_BILLING && (
                    <div className="membership-plans-tabbed-options__tabs">
                        <div
                            role="button"
                            tabIndex={0}
                            className={'tab ' + monthlyTabClassName}
                            onClick={handleTabClick(0)}
                            onKeyDown={noop}
                        >
                            {t('components.membership.membershipPlanTabs.monthly')}
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            className={'tab ' + annuallyTabClassName}
                            onClick={handleTabClick(1)}
                            onKeyDown={noop}
                        >
                            {t('components.membership.membershipPlanTabs.annually')}
                            <span className="tab_small ml-1">
                                ({t('components.membership.membershipPlanTabs.save')} {savingPercentage}%)
                            </span>
                        </div>
                    </div>
                )}
                <div className="membership-plans-tabbed-options__content">
                    {plansDetails &&
                        Object.values(plansDetails).map((plan: MembershipSinglePlanDetails) => {
                            const pricingMonthly = plan.membershipPlanAttributes[0].pricingMonthly;
                            const pricingAnnually = plan.membershipPlanAttributes[0].pricingAnnual;
                            const joinFeeAmount = plan.membershipPlanAttributes[0].joinFeeAmount;

                            const monthlyPlanPrice = pricingMonthly.toFixed(2);
                            const annuallyPlanPrice = (pricingAnnually / 12).toFixed(2);
                            const onDemandPlanPrice = joinFeeAmount.toFixed(2);

                            const isOnMonthlyTab = activeTab === 0;

                            const isOnDemandPlan = plan.membershipPlanId === 3;

                            const planPrice = isOnDemandPlan
                                ? onDemandPlanPrice
                                : isOnMonthlyTab
                                ? monthlyPlanPrice
                                : annuallyPlanPrice;

                            const isBilledAnnually = activeTab === 1 && [1, 2].includes(plan.membershipPlanId);

                            return (
                                <MembershipAvailablePlan
                                    planId={plan.membershipPlanId}
                                    planName={plan.membershipPlanDescription}
                                    planPrice={planPrice}
                                    isBilledAnnually={isBilledAnnually}
                                    attributes={plan.membershipPlanAttributes}
                                    savingPercentage={activeTab === 1 && plan.membershipPlanId !== 3 ? 15 : undefined}
                                    key={plan.membershipPlanId}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default MembershipPlansTabbedOptionsComponent;
