import { integer } from 'aws-sdk/clients/cloudfront';

import { PackageOption } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';

import { RxDetails } from 'types/prescription';

import axiosClientAws from 'util/axiosClientAws';
import { UnknownFunction } from 'util/function';

export interface DrugListObjectPayload {
    insuranceName: string;
    formulary: DrugLookupObjectPayload[];
}

export interface DrugLookupObjectPayload {
    brandDrugName: string;
    brandDrugNames?: string[];
    brandNameCode: string;
    deaClassCode: string;
    drugName: string;
    dosageForms?: DosageFormsObjectPayload[];
    onSaleDrugName: string;
    genericProductCode: string;
}

export interface DrugDetailsObjectPayload {
    dosageForms: DosageFormsObjectPayload[];
    drugName: string;
}

export interface DosageFormsObjectPayload {
    dosageForm: string;
    formCode: number;
    formDescription: string;
    formDisplay: string;
    isUoU?: boolean; // DRX-1056 enhancement
    gpi?: number; // DRX-1056 enhancement
    strengths: StrengthsObjectPayload[];
}

export interface DrugStrengthObjectPayload {
    key: string;
    label: string;
    value: string;
    drugCode: string;
}

export interface StrengthsObjectPayload {
    ddid: string;
    formCode: string;
    formDescription: string;
    fullStrength: string;
    gpi: string;
    isUoU: integer;
    ndc: string;
    packTotal: string;
    packUoM: string;
    packageDesc: string;
    packageDisplay: string;
    packageQuantity: number;
    packageSize: number;
    quantity90days: number;
    routeAdmin: string;
    strength: string;
    strengthUnit: string;
    packageOptions: PackageOptionPayload[]; // DRX-1056 enhancement
}

// DRX-1056 enhancement
export interface PackageOptionPayload
    extends Pick<PackageOption, 'key' | 'label' | 'value' | 'drugCode' | 'packageWithStrength'> {
    ndc: string;
    packTotal: string;
    packUoM: string;
    packageDesc: string;
    packageDisplay: string;
    packageQuantity: number;
    packageSize: number;
    quantity90days: string;
    isUoU: number;
    quantityLabelDesc?: string;
}

export interface DrugDescriptionObjectPayload {
    htmlDesc: string;
}

export interface DiscountLookupValues {
    drugCode: string;
    quantity: string;
    memberNumber?: string;
    daysSupply?: string;
    clientAcCode?: string;
    location?: string;
    zipCode?: string;
    gpi?: string;
}

export interface drugDiscountPriceRoutinePayload {
    prescriptions: RxDetails[];
    forceBirdiInsurance?: boolean;
    location?: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
    unAuthArea?: 'auto-refill' | 'easy-refill';
}

export interface drugListRoutinePayload {
    clientAcCode?: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface drugLookupRoutinePayload {
    drugName: string;
    clientAcCode?: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface drugDetailsRoutinePayload {
    drugName: string;
    gpi: string;
    clientAcCode?: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface drugDescriptionRoutinePayload {
    gpi: string;
    ndc?: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

// The response from the drug discount price lookup includes many other data
// points, but patientPayAmount and awpPrice are the only one we care about.
export interface DrugDiscountPricePayload {
    rxNumber: string;
    zipCode?: string;
    response: {
        member: {
            memberIdent: {
                clientAccountCode: string;
            };
        };
        isBirdiSelect: boolean;
        prescriptionResult: [
            {
                pricingInformation: {
                    memberCost: {
                        patientPayAmount: string;
                    };
                    awpPrice: string;
                };
                status: string;
            }
        ];
    };
}

/**
 * Anything related to drug lookup, drug pricing, etc. in the system.
 */
export default class DrugService {
    static drugList() {
        return {
            get: (payload: drugListRoutinePayload): Promise<any> => {
                const path = `drug/list?client-ac-code=${payload.clientAcCode}`;
                const request = axiosClientAws(path);
                return request.then((response: any) => response.data);
            }
        };
    }
    static drugLookup() {
        return {
            get: (payload: drugLookupRoutinePayload): Promise<any> => {
                let path = `druglookup?search-name=${encodeURIComponent(payload.drugName)}`;
                if (payload.clientAcCode) {
                    path += `&client-ac-code=${payload.clientAcCode}`;
                }
                const request = axiosClientAws(path);
                return request.then((response: any) => response.data);
            }
        };
    }
    static drugDetailsLookup() {
        return {
            get: (payload: drugDetailsRoutinePayload): Promise<any> => {
                let path = `drug?name=${encodeURIComponent(payload.drugName)}&gpi=${payload.gpi}`;
                if (payload.clientAcCode) {
                    path += `&client-ac-code=${payload.clientAcCode}`;
                }
                const request = axiosClientAws(path);
                return request.then((response: any) => {
                    const drugDetails = response.data;
                    // DRX-949 - Update the fullStrength value if it is a Unit of Use drug. This is because each "fullStrength"
                    // value is repeated in the payload, which is not valid when it is also used as the key in the select dropdown.
                    // The "fullStrength" string is based on the drug strength being isUoU
                    // and having an packUoM not equal to "EA".
                    // DRX-964 - Now uses packTotal as part of the reformatted fullStrengh value
                    // DRX-984 - Removed the overwriting of dosageForm, and now include the packageDisplay as part of the fullStrength
                    // DPM-522 / DRX-1266 - updated the packageDisplay flag in drug service and updated the strength.fullstrength to show units if packageDisplay is null
                    //
                    // IMPORTANT :: This change is also being done in gatsby-node.js
                    drugDetails.dosageForms.map((form) => {
                        form.strengths.map((strength) => {
                            if (!form.formDisplay && strength.formDisplay) {
                                form.formDisplay = strength.formDisplay;
                            }
                            if (strength.isUoU) {
                                if (strength.packageDisplay && strength.packageDisplay?.toLowerCase() !== 'null') {
                                    strength.fullStrength = `${strength.packTotal} of ${strength.fullStrength} (${strength.packageDisplay})`;
                                } else {
                                    strength.fullStrength = `${strength.packTotal} of ${strength.fullStrength} (Units)`;
                                }
                            }
                        });
                    });
                    return drugDetails;
                });
            }
        };
    }
    // DRX-1056 enhancement
    static drugFormLookup() {
        return {
            get: (payload: drugDetailsRoutinePayload): Promise<any> => {
                let path = `drug/form?name=${encodeURIComponent(payload.drugName)}&gpi=${payload.gpi}`;
                if (payload.clientAcCode) {
                    path += `&client-ac-code=${payload.clientAcCode}`;
                }
                const request = axiosClientAws(path);
                return request.then((response: any) => response.data);
            }
        };
    }
    static drugDiscountPriceLookup() {
        return {
            get: (payload: DiscountLookupValues): Promise<any> => {
                let path = `drug/medimpact-pricing?drug-code=${payload.drugCode}&quant-disp=${payload.quantity}`;
                // At some point, we may want or need to include the member
                // number, but for now we exclude it because it causes errors.
                // if (payload.memberNumber) {
                //     path += `&member-num=${payload.memberNumber}`;
                // }
                if (payload.daysSupply) {
                    path += `&days-supply=${payload.daysSupply}`;
                }
                if (payload.clientAcCode) {
                    path += `&client-ac-code=${payload.clientAcCode}`;
                }
                if (payload.location) {
                    path += `&app-location=${payload.location}`;
                }
                if (payload.zipCode) {
                    path += `&zip-code=${payload.zipCode}`;
                }
                if (payload.gpi) {
                    path += `&gpi=${payload.gpi}`;
                }

                const request = axiosClientAws(path);

                // We do not catch errors here. Instead, we let the
                // baseEffectHandler (called in the saga) catch the error.
                return request.then((response: any) => response.data);
            }
        };
    }
    static drugDescriptionLookup() {
        return {
            get: (payload: drugDescriptionRoutinePayload): Promise<any> => {
                // For now, ddi is a bogus value. At some point, we may need to
                // pass a legitimate value.
                let path = `drug/description?gpi=${payload.gpi}&ddi=123456`;

                // If the drug search includes an NDC parameter, it will be added to the path.
                if (payload.ndc) {
                    path += `&ndc=${payload.ndc}`;
                }
                const request = axiosClientAws(path);

                return request.then((response: any) => response.data);
            }
        };
    }
}
