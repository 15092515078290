import { GetEasyAutoRefillPatientDataResponse } from 'types/auto-refill';

/**
 * Validate if any user has rxs available to access to the auto refill flow.
 *
 * @param data The users inside the response of GetEasyAutoRefillPatientFamilyDataResponse
 */
export function hasRxInFamily(familyData: GetEasyAutoRefillPatientDataResponse[]) {
    for (const patient of familyData) {
        // Verify if any of the returned prescriptions are elegible.
        const eligibleRxs = patient.autoRefillFlags.filter((rx) => rx.autoRefillEligible).map((rx) => rx.rxNumber);
        const rxElegible = patient.rxResults.filter((rx) => eligibleRxs.includes(rx.rxNumber));
        if (rxElegible.length > 0) {
            return true;
        }
    }
    return false;
}
