// General
import { PayloadAction } from '@reduxjs/toolkit';
import { FormikHelpers } from 'formik';
import { REACT_APP_QNACLIENTFILTER, REGISTRATION_DOMAIN } from 'gatsby-env-variables';
import { put, select, takeLatest } from 'redux-saga/effects';

// Components
import { RegistrationFormValues } from 'components/registration-form/registration-form.component';

// Services
import AccountService, { AccountSetupPayload } from 'state/account/account.services';

// Types
import { CreateBirdiAccountPersonalInformation, MembershipPersonalDetailsFormValues } from 'types/membership';

// Utils
import { TrackError } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

// State
import {
    accountFinalizeNotInsuranced,
    formatAccountFinalizeNotInsurancedData,
    ValidateInviteMembershipPayload,
    ValidateInviteMembershipResponse
} from './membership-registration.helpers';
import {
    setAutoRefill,
    setMembershipIsCaliforniaUser,
    setPatientPlanAutoRefillFlag
} from './membership-registration.reducer';
import {
    membershipRegistrationConfirmPaymentRoutine,
    membershipRegistrationGetEmailZipcodeRoutine,
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetPlanDetailsRoutine,
    membershipRegistrationGetRacesRoutine,
    membershipRegistrationPromoCodeRoutine,
    membershipRegistrationRegisterRoutine,
    membershipRegistrationUninsuredPaymetricCredentialsRoutine,
    membershipRegistrationUninsuredRegisterRoutine,
    membershipRegistrationUninsuredTokenizedCardNumberRoutine,
    membershipRegistrationValidateInsuranceIdRoutine,
    membershipRegistrationValidateInviteRoutine
} from './membership-registration.routines';
import { membershipRegistrationResponseErrorPayloadMembershipSelector } from './membership-registration.selectors';
import {
    MembershipEmailZipcodePayload,
    MembershipEthnicitiesPayload,
    MembershipGendersPayload,
    MembershipRacesPayload,
    MembershipRegistrationService,
    PatientRegistrationPayload
} from './membership-registration.services';

function* getMembershipGendersData(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        yield baseEffectHandler({
            service: MembershipRegistrationService.getMembershipGenders().get,
            isAuthenticatedService: false,
            data: action.payload,
            *onResponse(response: MembershipGendersPayload) {
                yield put(membershipRegistrationGetGendersRoutine.success(response?.genders || []));
            },
            *onError() {
                yield put(membershipRegistrationGetGendersRoutine.failure());
            }
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        yield put(membershipRegistrationGetGendersRoutine.failure());
    }
}

function* getMembershipRacesData(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        yield baseEffectHandler({
            service: MembershipRegistrationService.getMembershipRaces().get,
            isAuthenticatedService: false,
            data: action.payload,
            *onResponse(response: MembershipRacesPayload) {
                yield put(membershipRegistrationGetRacesRoutine.success(response?.races || []));
            },
            *onError() {
                yield put(membershipRegistrationGetRacesRoutine.failure());
            }
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        yield put(membershipRegistrationGetRacesRoutine.failure());
    }
}

function* getMembershipEthnicitiesData(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        yield baseEffectHandler({
            service: MembershipRegistrationService.getMembershipEthnicities().get,
            isAuthenticatedService: false,
            data: action.payload,
            *onResponse(response: MembershipEthnicitiesPayload) {
                yield put(membershipRegistrationGetEthnicitiesRoutine.success(response?.ethnicities || []));
            },
            *onError() {
                yield put(membershipRegistrationGetEthnicitiesRoutine.failure());
            }
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        yield put(membershipRegistrationGetEthnicitiesRoutine.failure());
    }
}

function* getMembershipEmailZipcodeData(
    action: PayloadAction<{
        email: string;
        zipcode: string;
        validate: 'valid' | 'invalid' | 'registred';
        onSuccess?: () => void;
        onFailure?: (error: any) => void;
    }>
) {
    try {
        yield baseEffectHandler({
            service: MembershipRegistrationService.getEmailZipcodeLookup(action.payload.email, action.payload.zipcode)
                .get,
            isAuthenticatedService: false,
            data: action.payload,
            *onResponse(response: MembershipEmailZipcodePayload) {
                const { messageStatus, messageText } = response;
                const { onSuccess } = action.payload;
                const { onFailure } = action.payload;

                // existing accounts return one of the following error responses
                const errorResponses = ['Unable to confirm the information provided', 'Account already exists'];
                const hasExistingAccount = errorResponses.some((err) => err.includes(messageText));
                const result: MembershipEmailZipcodePayload = {
                    ...response
                };

                if (!messageStatus) {
                    // "unable to confirm the information provided" -OR- "account already exists"
                    if (hasExistingAccount) {
                        // raise error modal here "Email address is already in use. Enter a different email."
                        result.validate = 'registered';
                    } else {
                        result.validate = 'invalid';
                    }

                    yield put(membershipRegistrationGetEmailZipcodeRoutine.failure(result));
                    if (onFailure) onFailure(result);
                } else {
                    result.validate = 'valid';
                    yield put(membershipRegistrationGetEmailZipcodeRoutine.success(result));
                    // have confirmation from the API
                    if (onSuccess) onSuccess();
                }
            }
        });
    } catch (error: any) {
        const { onFailure } = action.payload;
        if (onFailure) onFailure({});
        yield put(membershipRegistrationGetEmailZipcodeRoutine.failure());
        TrackError('membership-registration.sagas.ts', 'getMembershipEmailZipcode', error);
    }
}

function* validateInsuranceIdData(
    action: PayloadAction<{
        formValues: Partial<CreateBirdiAccountPersonalInformation>;
        insuranceId: string;
        validate: 'valid' | 'invalid' | 'registered';
        formHelpers: FormikHelpers<Partial<RegistrationFormValues>>;
        errorMessage: string | undefined;
        onSuccess?: (data: any) => void;
        onFailure?: (error: any) => void;
    }>
) {
    try {
        yield baseEffectHandler<RegistrationFormValues>({
            service: MembershipRegistrationService.insuranceLookup(action.payload.insuranceId).post,
            data: action.payload.formValues,
            *onResponse(data: PatientRegistrationPayload) {
                if (data.messageStatus) {
                    yield baseEffectHandler({
                        service: MembershipRegistrationService.validateInsuranceId(action.payload.insuranceId).get,
                        data: action.payload.formValues,
                        *onResponse(data: PatientRegistrationPayload) {
                            const { onSuccess, onFailure } = action.payload;

                            // If the patient is already registered, set an error state.
                            if (data.RegCode === 3 && data.messageErrorText === 'Patient is already registered.') {
                                if (onFailure) onFailure(data);
                                yield put(
                                    membershipRegistrationValidateInsuranceIdRoutine.failure({
                                        formValues: action.payload.formValues,
                                        insuranceId: action.payload.insuranceId,
                                        validate: 'registered'
                                    })
                                );
                            } else {
                                if (onSuccess) onSuccess(data);
                                yield put(
                                    membershipRegistrationValidateInsuranceIdRoutine.success({
                                        formValues: action.payload.formValues,
                                        insuranceId: action.payload.insuranceId,
                                        validate: 'valid'
                                    })
                                );
                            }

                            if (data.StateCode === 'CA') {
                                yield put(setMembershipIsCaliforniaUser(true));
                            }
                            // Update state with values returned from API for AutoRefill and patientPlanAutoRefillFlag
                            yield put(setAutoRefill(!!data.AutoRefill));
                            yield put(setPatientPlanAutoRefillFlag(!!data.patientPlanAutoRefillFlag));
                        },
                        *onError(data: PatientRegistrationPayload) {
                            const { onFailure } = action.payload;
                            if (onFailure) onFailure(data);

                            const { ErrorMessage, SystemMessage } = data || {};
                            // If the registration failed, set an error state.
                            yield put(
                                membershipRegistrationValidateInsuranceIdRoutine.failure({
                                    patientRegistrationPayload: data?.response?.response?.data,
                                    formValues: action.payload.formValues,
                                    insuranceId: action.payload.insuranceId,
                                    validate: 'error'
                                })
                            );

                            // error tracking
                            if (SystemMessage) {
                                TrackError(
                                    'membership-registration.sagas.ts',
                                    'validateInsuranceIdData',
                                    SystemMessage
                                );
                            } else {
                                TrackError(
                                    'membership-registration.sagas.ts',
                                    'validateInsuranceIdData',
                                    ErrorMessage ? ErrorMessage : 'An unknown error occurred.'
                                );
                            }
                        }
                    });
                } else {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(
                        membershipRegistrationValidateInsuranceIdRoutine.failure({
                            patientRegistrationPayload: data?.response?.response?.data,
                            formValues: action.payload.formValues,
                            insuranceId: action.payload.insuranceId,
                            validate: 'error'
                        })
                    );
                }
            },
            *onError(data: PatientRegistrationPayload) {
                const { ErrorMessage, SystemMessage } = data || {};
                const { onFailure } = action.payload;
                if (onFailure) onFailure(data);

                // If the registration failed, set an error state.
                yield put(
                    membershipRegistrationValidateInsuranceIdRoutine.failure({
                        patientRegistrationPayload: data?.response?.response?.data,
                        formValues: action.payload.formValues,
                        insuranceId: action.payload.insuranceId,
                        validate: 'error'
                    })
                );

                // error tracking
                if (SystemMessage) {
                    TrackError('membership-registration.sagas.ts', 'validateInsuranceIdData', SystemMessage);
                } else {
                    TrackError(
                        'membership-registration.sagas.ts',
                        'validateInsuranceIdData',
                        ErrorMessage ? ErrorMessage : 'An unknown error occurred.'
                    );
                }
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;
        if (onFailure) onFailure();
        TrackError('membership-registration.sagas.ts', 'insuranceLookup', error);

        yield put(
            membershipRegistrationValidateInsuranceIdRoutine.failure({
                patientRegistrationPayload: {},
                formValues: action.payload.formValues,
                validate: 'invalid'
            })
        );
    }
}

function* validateInviteSaga(
    action: PayloadAction<{
        validateInviteData: ValidateInviteMembershipPayload;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    try {
        const { validateInviteData, onSuccess, onFailure } = action.payload;

        yield baseEffectHandler({
            service: MembershipRegistrationService.validateInvite().get,
            isAuthenticatedService: false,
            data: validateInviteData,
            *onResponse(response: ValidateInviteMembershipResponse) {
                if (response.messageStatus) {
                    yield put(membershipRegistrationValidateInviteRoutine.success(response));
                    if (onSuccess) onSuccess();
                } else {
                    yield put(membershipRegistrationValidateInviteRoutine.failure());
                    if (onFailure) onFailure();
                }
            },
            *onError() {
                yield put(membershipRegistrationValidateInviteRoutine.failure());
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;

        yield put(membershipRegistrationGetPlanDetailsRoutine.failure());
        if (onFailure) onFailure();
    }
}

function* getPlanDetailsData(
    action: PayloadAction<{ onFailure?: () => void; onSuccess?: () => void; promoCode?: string }>
) {
    try {
        yield baseEffectHandler({
            service: MembershipRegistrationService.planDetails(action?.payload?.promoCode).get,
            isAuthenticatedService: false,
            data: action.payload,
            *onResponse(response: any) {
                if (action?.payload?.promoCode) {
                    yield put(membershipRegistrationPromoCodeRoutine.fulfill());
                    if (response.messageStatus === false) {
                        yield put(membershipRegistrationPromoCodeRoutine.failure());
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    } else {
                        yield put(membershipRegistrationPromoCodeRoutine.success(response?.plansDetails || []));
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    }
                } else {
                    yield put(membershipRegistrationGetPlanDetailsRoutine.success(response?.plansDetails || []));
                }
            },
            *onError() {
                if (action?.payload?.promoCode) {
                    yield put(membershipRegistrationPromoCodeRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                } else {
                    yield put(membershipRegistrationGetPlanDetailsRoutine.failure());
                }
            }
        });
    } catch (error) {
        if (action?.payload?.promoCode) {
            yield put(membershipRegistrationPromoCodeRoutine.failure());
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
        } else {
            yield put(membershipRegistrationGetPlanDetailsRoutine.failure());
        }
    }
}

function* membershipRegister(
    action: PayloadAction<{
        formValues: Partial<PatientRegistrationPayload>;
        formHelpers: FormikHelpers<Partial<MembershipPersonalDetailsFormValues>>;
        errorMessage: string | undefined;
        onSuccess?: () => void;
        onFailure?: (error?: any) => void;
    }>
) {
    try {
        yield baseEffectHandler<MembershipPersonalDetailsFormValues>({
            service: AccountService.accountSetup().post,
            data: action.payload.formValues,
            *onResponse({ data }: { data: AccountSetupPayload }) {
                const { formValues } = action.payload;
                const finalizeBody = {
                    ...data,
                    Email: formValues.Email,
                    Password: formValues.Password,
                    PhoneNumber: formValues.PhoneNumber,
                    UserName: formValues.Email,
                    Payor: REACT_APP_QNACLIENTFILTER,
                    Domain: REGISTRATION_DOMAIN
                };

                yield baseEffectHandler({
                    service: AccountService.accountFinalize().post,
                    data: finalizeBody,
                    *onResponse({ data }: { data: PatientRegistrationPayload }) {
                        yield put(membershipRegistrationRegisterRoutine.success({}));
                        const { onSuccess } = action.payload;
                        if (onSuccess) {
                            onSuccess();
                        }
                    },
                    *onError(error: any) {
                        action.payload.formHelpers.setSubmitting(false);
                        action.payload.formHelpers.setStatus(
                            action.payload.errorMessage
                                ? action.payload.errorMessage
                                : 'There was an error finalizing your registration'
                        );
                        yield put(membershipRegistrationRegisterRoutine.failure({}));
                        const { onFailure } = action.payload;
                        if (onFailure) {
                            onFailure(error.response?.response?.data);
                        }
                    }
                });
            },
            *onError() {
                const { onFailure } = action.payload;
                yield put(membershipRegistrationRegisterRoutine.failure({}));
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;
        yield put(membershipRegistrationRegisterRoutine.failure({}));
        if (onFailure) onFailure();
    }
}

function* membershipUninsuredPaymetricCredentials(
    action: PayloadAction<{
        errorMessage: string | undefined;
        onSuccess?: (data: any) => void;
        onFailure?: (error: any) => void;
    }>
) {
    try {
        yield baseEffectHandler({
            service: MembershipRegistrationService.UnauthorizedPayments().getPaymetricCredentials,
            isAuthenticatedService: false,
            data: {},
            *onResponse(paymetricDetails: any) {
                yield put(membershipRegistrationUninsuredPaymetricCredentialsRoutine.success({}));
                const { onSuccess } = action.payload;
                if (onSuccess)
                    onSuccess({
                        merchantId: paymetricDetails?.MerchantId,
                        accessToken: paymetricDetails?.AccessToken,
                        serviceUrl: paymetricDetails?.ServiceUrl,
                        scriptUrl: paymetricDetails?.ScriptUrl
                    });
            },
            *onError(error) {
                yield put(membershipRegistrationUninsuredPaymetricCredentialsRoutine.failure({}));
                const { onFailure } = action.payload;
                if (onFailure) onFailure({});
            }
        });
    } catch (error) {
        yield put(membershipRegistrationUninsuredPaymetricCredentialsRoutine.failure({}));
    }
}

function* membershipUninsuredTokenizedCardNumber(
    action: PayloadAction<{
        formValues: any;
        errorMessage: string | undefined;
        onSuccess?: (data: any) => void;
        onFailure?: (error: any) => void;
    }>
) {
    try {
        const { formValues } = action.payload;

        yield baseEffectHandler({
            service: MembershipRegistrationService.UnauthorizedPayments().getTokenizedCardNumber,
            isAuthenticatedService: false,
            data: formValues?.AccessToken,
            *onResponse(pciToken: any) {
                yield put(membershipRegistrationUninsuredTokenizedCardNumberRoutine.success({}));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess({ pciToken: pciToken });
            },
            *onError(error) {
                yield put(membershipRegistrationUninsuredTokenizedCardNumberRoutine.failure({}));
                const { onFailure } = action.payload;
                if (onFailure) onFailure({});
            }
        });
    } catch (error) {
        yield put(membershipRegistrationUninsuredTokenizedCardNumberRoutine.failure({}));
    }
}

function* membershipUninsuredRegister(
    action: PayloadAction<{
        formValues: any;
        errorMessage: string | undefined;
        onSuccess?: () => void;
        onFailure?: (error: any) => void;
    }>
) {
    try {
        const { formValues } = action.payload;
        const responseErrorPayload: accountFinalizeNotInsuranced = yield select(
            membershipRegistrationResponseErrorPayloadMembershipSelector
        );

        const accountValues = formatAccountFinalizeNotInsurancedData(formValues, responseErrorPayload);

        yield baseEffectHandler({
            service: AccountService.accountFinalizeNotInsuranced().post,
            data: accountValues,
            *onResponse(data: accountFinalizeNotInsuranced) {
                if (data.messageErrorText) {
                    yield put(membershipRegistrationUninsuredRegisterRoutine.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(data.messageErrorText);
                } else {
                    yield put(membershipRegistrationUninsuredRegisterRoutine.success({}));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error: any) {
                yield put(membershipRegistrationUninsuredRegisterRoutine.failure({}));
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error);
            }
        });
    } catch (error) {
        yield put(membershipRegistrationUninsuredRegisterRoutine.failure({}));
        const { onFailure } = action.payload;
        if (onFailure) onFailure(error);
    }
}

function* membershipConfirmPayment(
    action: PayloadAction<{
        email: string;
        onSuccess?: (response: { alreadyActivated: boolean }) => void;
        onFailure?: (error: any) => void;
    }>
) {
    try {
        const { email } = action.payload;

        yield baseEffectHandler({
            service: MembershipRegistrationService.confirmPayment().post,
            data: email,
            *onResponse(data: any) {
                const alreadyActivatedMsg = ['Membership is already activated', 'Patient is already activated'];
                const failureMessagesMsg = ['Birdi Patient not membership', 'Email confirmed but payment has failed'];

                if (
                    !data.messageStatus &&
                    !failureMessagesMsg.includes(data.messageText) &&
                    !alreadyActivatedMsg.includes(data.messageText)
                ) {
                    yield put(membershipRegistrationConfirmPaymentRoutine.failure({}));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure({});
                } else {
                    yield put(membershipRegistrationConfirmPaymentRoutine.success({}));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess({ alreadyActivated: alreadyActivatedMsg.includes(data.messageText) });
                }
            },
            *onError(error: any) {
                yield put(membershipRegistrationConfirmPaymentRoutine.failure({}));
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error);
            }
        });
    } catch (error) {
        yield put(membershipRegistrationConfirmPaymentRoutine.failure({}));
        const { onFailure } = action.payload;
        if (onFailure) onFailure(error);
    }
}

function* membershipRegistrationSaga() {
    yield takeLatest(membershipRegistrationGetGendersRoutine.TRIGGER, getMembershipGendersData);
    yield takeLatest(membershipRegistrationGetRacesRoutine.TRIGGER, getMembershipRacesData);
    yield takeLatest(membershipRegistrationGetEthnicitiesRoutine.TRIGGER, getMembershipEthnicitiesData);
    yield takeLatest(membershipRegistrationGetEmailZipcodeRoutine.TRIGGER, getMembershipEmailZipcodeData);
    yield takeLatest(membershipRegistrationValidateInsuranceIdRoutine.TRIGGER, validateInsuranceIdData);
    yield takeLatest(membershipRegistrationValidateInviteRoutine.TRIGGER, validateInviteSaga);
    yield takeLatest(membershipRegistrationGetPlanDetailsRoutine.TRIGGER, getPlanDetailsData);
    yield takeLatest(membershipRegistrationPromoCodeRoutine.TRIGGER, getPlanDetailsData);
    yield takeLatest(membershipRegistrationRegisterRoutine.TRIGGER, membershipRegister);
    yield takeLatest(membershipRegistrationUninsuredRegisterRoutine.TRIGGER, membershipUninsuredRegister);
    yield takeLatest(
        membershipRegistrationUninsuredPaymetricCredentialsRoutine.TRIGGER,
        membershipUninsuredPaymetricCredentials
    );
    yield takeLatest(
        membershipRegistrationUninsuredTokenizedCardNumberRoutine.TRIGGER,
        membershipUninsuredTokenizedCardNumber
    );
    yield takeLatest(membershipRegistrationConfirmPaymentRoutine, membershipConfirmPayment);
}
export default membershipRegistrationSaga;
