import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';

import { accountIsLoggedInSelector } from 'state/account/account.selectors';

// Interfaces and Types
import {
    CaregiversChangeAccessPayload,
    FamilyAccountAddDependentSagaPayload,
    FamilyAccountGetDependentsPayload,
    FamilyAccountRemoveDependentSagaPayload,
    FamilyAccountSendDependentInvitationSagaPayload,
    FamilyAccountVerifyDependentSagaPayload
} from 'types/family-management';

import { baseEffectHandler } from 'util/sagas/sagas';

// Routines
import {
    familyProfileAddDependentRoutine,
    familyProfileGetCaregiversRoutine,
    familyProfileGetDependentsRoutine,
    familyProfilePostChangeCaregiversAccessRoutine,
    familyProfileRemoveDependentRoutine,
    familyProfileSendDependentInvitationRoutine,
    familyProfileVerifyDependentRoutine
} from './family-profile.routines';
import { familyProfileDependentsSelector } from './family-profile.selectors';
// Services
import FamilyProfileService from './family-profile.services';

function* getDependentsSaga(action: PayloadAction<any>) {
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    yield baseEffectHandler({
        service: FamilyProfileService.dependents().get,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload,
        *onResponse(response: FamilyAccountGetDependentsPayload) {
            if (response.Success === false) {
                yield put(familyProfileGetDependentsRoutine.failure({ messageText: response.Message }));
            }
            yield put(familyProfileGetDependentsRoutine.success(response));
        },
        *onError(error) {
            yield put(familyProfileGetDependentsRoutine.failure({ messageText: error }));
        }
    });
}

function* verifyDependentSaga(action: PayloadAction<FamilyAccountVerifyDependentSagaPayload>) {
    const { dependentData, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: FamilyProfileService.dependents().verify,
        isAuthenticatedService: true,
        isLoggedIn,
        data: dependentData,
        *onResponse(response) {
            if (response.Success === false) {
                yield put(familyProfileVerifyDependentRoutine.failure({ messageText: response.Errors }));
                if (onFailure) onFailure(response.Errors);
            } else {
                const responseDataThreated = {
                    ...response.Data,
                    ...(dependentData.PhoneNumber !== undefined && { PhoneNumber: dependentData.PhoneNumber })
                };
                yield put(familyProfileVerifyDependentRoutine.success(responseDataThreated));
                if (onSuccess) onSuccess(responseDataThreated);
            }
        },
        *onError(error) {
            yield put(
                familyProfileVerifyDependentRoutine.failure({ messageText: error.response.response.data.Errors })
            );
            if (onFailure) onFailure(error.response.response.data.Errors);
        }
    });
}

function* addDependentSaga(action: PayloadAction<FamilyAccountAddDependentSagaPayload>) {
    const { dependentData, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: FamilyProfileService.dependents().add,
        isAuthenticatedService: true,
        isLoggedIn,
        data: dependentData,
        *onResponse(response) {
            if (response.Success === false) {
                yield put(familyProfileAddDependentRoutine.failure({ messageText: response.Errors }));
                if (onFailure) onFailure(response.Errors);
            } else {
                yield put(familyProfileAddDependentRoutine.success(response));
                if (onSuccess) onSuccess(response);
            }
        },
        *onError(error) {
            yield put(familyProfileAddDependentRoutine.failure({ messageText: error.response.response.data.Errors }));
            if (onFailure) onFailure(error.response.response.data.Errors);
        }
    });
}

function* removeDependentSaga(
    action: PayloadAction<FamilyAccountRemoveDependentSagaPayload>
): Generator<any, void, any> {
    const { DependentID, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const dependents: any | undefined = yield select(familyProfileDependentsSelector);

    yield baseEffectHandler({
        service: FamilyProfileService.dependents().remove,
        isAuthenticatedService: true,
        isLoggedIn,
        data: { DependentId: Number(DependentID) },
        *onResponse(response) {
            if (response.Success === false) {
                yield put(familyProfileRemoveDependentRoutine.failure({ messageText: response.Errors }));
                if (onFailure) onFailure(response.Errors);
            } else {
                yield put(familyProfileRemoveDependentRoutine.success({ id: Number(DependentID), dependents }));
                if (onSuccess) onSuccess(response);
            }
        },
        *onError(error) {
            yield put(
                familyProfileRemoveDependentRoutine.failure({
                    messageText: error.response.response.data.Errors
                })
            );
            if (onFailure) onFailure(error.response.response.data.Errors);
        }
    });
}

function* sendInvitationSaga(action: PayloadAction<FamilyAccountSendDependentInvitationSagaPayload>) {
    const { data, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: FamilyProfileService.dependents().sendInvitation,
        isAuthenticatedService: true,
        isLoggedIn,
        data: data,
        *onResponse(response) {
            if (response.Success === false) {
                yield put(familyProfileSendDependentInvitationRoutine.failure({ messageText: response.Errors }));
                if (onFailure) onFailure(response.Errors);
            } else {
                // reload dependents
                yield put(familyProfileGetDependentsRoutine.trigger());

                yield put(familyProfileSendDependentInvitationRoutine.success());
                if (onSuccess) onSuccess(response);
            }
        },
        *onError(error) {
            yield put(
                familyProfileSendDependentInvitationRoutine.failure({
                    messageText: error.response.response.data.Errors
                })
            );
            if (onFailure) onFailure(error.response.response.data.Errors);
        }
    });
}

function* getCaregiversSaga() {
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: FamilyProfileService.getCaregivers().get,
        isAuthenticatedService: true,
        isLoggedIn,
        *onResponse(response) {
            yield put(familyProfileGetCaregiversRoutine.success(response.Data));
        },
        *onError(error) {
            yield put(familyProfileGetCaregiversRoutine.failure({ messageText: error }));
        }
    });
}

function* changeCaregiversAccessSaga(
    action: PayloadAction<{
        data: CaregiversChangeAccessPayload;
        onSuccess?: () => void;
        onFailure?: (response: string) => void;
    }>
) {
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    const { data, onFailure, onSuccess } = action.payload;

    yield baseEffectHandler({
        service: FamilyProfileService.changeCaregiversAccess().post,
        isAuthenticatedService: true,
        isLoggedIn,
        data: data,
        *onResponse(response) {
            yield put(familyProfilePostChangeCaregiversAccessRoutine.success(response));
            if (onSuccess) onSuccess();
        },
        *onError(error) {
            yield put(familyProfilePostChangeCaregiversAccessRoutine.failure({ messageText: error }));
            if (onFailure) onFailure(error.response.response.data.messageText);
        }
    });
}

function* familyAccountSaga() {
    yield takeLatest(familyProfileGetDependentsRoutine.TRIGGER, getDependentsSaga);
    yield takeLatest(familyProfileAddDependentRoutine.TRIGGER, addDependentSaga);
    yield takeLatest(familyProfileVerifyDependentRoutine.TRIGGER, verifyDependentSaga);
    yield takeLatest(familyProfileSendDependentInvitationRoutine.TRIGGER, sendInvitationSaga);
    yield takeLatest(familyProfileGetCaregiversRoutine.TRIGGER, getCaregiversSaga);
    yield takeLatest(familyProfilePostChangeCaregiversAccessRoutine.TRIGGER, changeCaregiversAccessSaga);
    yield takeLatest(familyProfileRemoveDependentRoutine.TRIGGER, removeDependentSaga);
}

export default familyAccountSaga;
