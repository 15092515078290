import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { DEFAULT_SHIPPING_ID } from 'util/cart';

import {
    accountCreditCardsSelector,
    accountProfileAddressesSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from '../account/account.selectors';
import { getAllEpostsCarts, getAllItemsTotal, getAllOrderTotal, getMainCart } from './cart.helpers';

export const CartSelector = (state: RootState) => state.cartReducer;

export const cartSelector = createSelector(CartSelector, (cart) => cart.cart);

export const cartItemsSelector = createSelector(CartSelector, (state) =>
    state.cart?.flatMap((cart) => cart.Order.refillRxs)
);

export const cartItemsTotalSelector = createSelector(cartSelector, (carts) => getAllItemsTotal(carts));

export const cartSubtotalSelector = createSelector(cartSelector, (carts) => getAllOrderTotal(carts));

export const cartOrderBillShipSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector],
    (carts, EpostPatientNum) => {
        const cart = carts?.find((cart) => cart?.Order.orderBillShip.patientBillAddressSeq !== null);
        return cart?.Order.orderBillShip;
    }
);

export const cartOrderBillShipMethodSelector = createSelector(
    CartSelector,
    (cart) => cart?.shipMethodId || DEFAULT_SHIPPING_ID
);

export const cartOrderHighPriority = createSelector([cartSelector], (carts) => {
    const cart = carts?.find((cart) => cart?.Order.orderBillShip.shipMethodId !== null);
    return cart?.Order.orderHeader.orderHighPriority;
});

export const cartIsBusySelector = createSelector(CartSelector, (cart) => cart.isBusy || false);

export const cartOrderShippingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector, accountProfilIsCaregiverSelector],
    (addresses, carts, isCaregiver) => {
        const cart = carts?.find((cart) => cart?.Order.orderBillShip.patientShipAddressSeq !== null);
        const addressSeqNum =
            !isCaregiver && cart?.Order.orderBillShip.dependentAddressSeqNum
                ? cart?.Order.orderBillShip.dependentAddressSeqNum
                : cart?.Order.orderBillShip.patientShipAddressSeq;
        return addresses.find((address) => address.addressSeqNum === addressSeqNum);
    }
);

export const cartOrderBillingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector, accountProfilIsCaregiverSelector],
    (addresses, carts, isCaregiver) => {
        const cart = carts?.find((cart) => cart?.Order.orderBillShip.patientBillAddressSeq !== null);
        const addressSeqNum =
            !isCaregiver && cart?.Order.orderBillShip.dependentAddressSeqNum
                ? cart?.Order.orderBillShip.dependentAddressSeqNum
                : cart?.Order.orderBillShip.patientBillAddressSeq;
        return addresses.find((address) => address.addressSeqNum === addressSeqNum);
    }
);

export const cartOrderPaymentCardSelector = createSelector(
    [accountCreditCardsSelector, cartSelector, accountProfilIsCaregiverSelector],
    (cards, carts, isCaregiver) => {
        const cart = carts?.find((cart) => cart?.Order.orderBillShip.paymentCardSeqNum !== null);
        const cardSeqNum =
            !isCaregiver && cart?.Order.orderBillShip.dependentPaymentSeqNum
                ? cart?.Order.orderBillShip.dependentPaymentSeqNum
                : cart?.Order.orderBillShip.paymentCardSeqNum;
        return cards?.find((card) => card.cardSeqNum === cardSeqNum);
    }
);

export const cartOrderHeaderSelector = createSelector([cartSelector], (carts) => {
    /**
     * Bugfix DRX-2275: As caregiver accounts uses the Birdi Order Number information,
     * and Complete Order for non-caregiver accounts don't have the EpostNum to be
     * possible to use the getMainCart function, we can just return the first position
     * for the cart object.
     */
    return carts && carts[0]?.Order.orderHeader;
});

export const cartOrderBirdiOrderNumberSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector, accountProfileSelector, accountProfilIsCaregiverSelector],
    (carts, EpostPatientNum, profileObject, isCaregiver) => {
        if (!isCaregiver) return [];

        const epostTargets = [
            ...profileObject!.dependents.map((dependent) => dependent.epostPatientNum),
            EpostPatientNum
        ];
        const dependentCarts = getAllEpostsCarts(carts, epostTargets);

        return dependentCarts && dependentCarts[0].BirdiOrderNumber;
    }
);

export const cartOrderPlacedSelector = createSelector(CartSelector, (cart) => cart.orderPlaced);

export const isCartWithoutPaymentMethodSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector],
    (carts, epostPatientNum) => {
        const currentCart = getMainCart(carts, epostPatientNum);

        const noPaymentInCurrentCart = currentCart?.Order.orderBillShip.paymentCardSeqNum === null;

        return noPaymentInCurrentCart;
    }
);
